// core
import React from 'react';
import userGuide from './user-guide.inline.svg';

export default {
  billing,
  logout,
  upgrage,
  user,
  logo,
  stats,
  statsWarning,
  camera,
  cursor,
  parallel,
  userGuide,
};

function parallel({ color }) {
  return (
    <svg
      className="header__limits-and-stats-panel-list-item-icon"
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 2.50019C13.2 3.00019 13.2 4.00019 12.5 4.40019L8.6 6.80019C8 7.30019 7 6.80019 7 6.00019V5.00019H1C0.4 5.00019 0 4.60019 0 4.00019V3.00019C0 2.40019 0.4 2.00019 1 2.00019H7V1.00019C7 0.200191 8 -0.299809 8.6 0.200191L12.5 2.50019ZM15.5 10.5003C16.2 11.0003 16.2 12.0003 15.5 12.4003L11.6 14.8003C10.9 15.3003 10 14.8003 10 14.0003V13.0003H1C0.4 13.0003 0 12.6003 0 12.0003V11.0003C0 10.4003 0.4 10.0003 1 10.0003H10V9.00025C10 8.20025 10.9 7.70025 11.6 8.10025L15.5 10.5003Z"
        fill={color ? color : '#606772'}
      />
    </svg>
  );
}

function cursor({ color }) {
  return (
    <svg
      className="header__limits-and-stats-panel-list-item-icon"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6663 5.19894L2.39806 1.09164C1.55793 0.718248 0.717801 1.55838 1.09119 2.30516L5.19849 12.5734C5.47853 13.2268 6.31866 13.4135 6.7854 12.9468L8.46566 11.2665L11.9195 14.7204C12.2929 15.0938 12.9464 15.0938 13.3197 14.7204L14.72 13.3202C15.0933 12.9468 15.0933 12.2934 14.72 11.92L11.2661 8.46611L12.9464 6.78585C13.5064 6.31911 13.3197 5.47898 12.6663 5.19894Z"
        fill={color}
      />
    </svg>
  );
}

function camera({ color }) {
  return (
    <svg
      className="header__limits-and-stats-panel-list-item-icon"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 4H12V2C12 1.4 11.6 1 11 1H5C4.4 1 4 1.4 4 2V4H1C0.4 4 0 4.4 0 5V14C0 14.6 0.4 15 1 15H15C15.6 15 16 14.6 16 14V5C16 4.4 15.6 4 15 4ZM8 12C6.3 12 5 10.7 5 9C5 7.3 6.3 6 8 6C9.7 6 11 7.3 11 9C11 10.7 9.7 12 8 12Z"
        fill={color}
      />
    </svg>
  );
}

function statsWarning() {
  return (
    <svg
      className="header__limits-and-stats-btn-icon-warning"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C14.4 2 18 5.6 18 10C18 14.4 14.4 18 10 18C5.6 18 2 14.4 2 10C2 5.6 5.6 2 10 2ZM10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM10 5C9.4 5 9 5.4 9 6V10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10V6C11 5.4 10.6 5 10 5ZM8.5 13.5C8.5 12.7 9.2 12 10 12C10.8 12 11.5 12.7 11.5 13.5C11.5 14.3 10.8 15 10 15C9.2 15 8.5 14.3 8.5 13.5Z"
        fill="#FF763B"
      />
    </svg>
  );
}

function stats() {
  return (
    <svg
      className="header__limits-and-stats-btn-icon-normal"
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="header__limits-and-stats-btn-icon-normal-path"
        d="M17 5H1C0.4 5 0 5.4 0 6C0 6.6 0.4 7 1 7H17C17.6 7 18 6.6 18 6C18 5.4 17.6 5 17 5Z"
        fill="#90969F"
      />
      <path
        className="header__limits-and-stats-btn-icon-normal-path"
        d="M14 10H1C0.4 10 0 10.4 0 11C0 11.6 0.4 12 1 12H14C14.6 12 15 11.6 15 11C15 10.4 14.6 10 14 10Z"
        fill="#90969F"
      />
      <path
        className="header__limits-and-stats-btn-icon-normal-path"
        d="M11 0H1C0.4 0 0 0.4 0 1C0 1.6 0.4 2 1 2H11C11.6 2 12 1.6 12 1C12 0.4 11.6 0 11 0Z"
        fill="#90969F"
      />
      <path
        className="header__limits-and-stats-btn-icon-normal-path"
        d="M8 15H1C0.4 15 0 15.4 0 16C0 16.6 0.4 17 1 17H8C8.6 17 9 16.6 9 16C9 15.4 8.6 15 8 15Z"
        fill="#90969F"
      />
    </svg>
  );
}

function billing() {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2 0.267914L14.4 2.26791C14.2 2.36791 14 2.36791 13.8 2.26791L11.8 0.767914C11.6 0.667914 11.4 0.667914 11.2 0.767914L9.2 2.26791C9 2.36791 8.8 2.36791 8.6 2.26791L6.6 0.767914C6.4 0.667914 6.2 0.667914 6 0.767914L4 2.26791C4 2.46791 3.7 2.46791 3.6 2.26791L0.8 0.267914C0.5 0.0679141 0 0.267914 0 0.667914V16.6679C0 17.4679 0.7 18.1679 1.5 18.1679H16.5C17.3 18.1679 18 17.4679 18 16.6679V0.667914C18 0.267914 17.5 0.0679141 17.2 0.267914ZM2.5 16.1679C2.2 16.1679 2 15.9679 2 15.6679V3.56791L3.6 4.76791C3.8 4.86791 4 4.86791 4.2 4.76791L6.1 3.26791C6.3 3.16791 6.6 3.16791 6.7 3.26791L8.7 4.76791C8.9 4.86791 9.1 4.86791 9.3 4.76791L11.3 3.26791C11.5 3.16791 11.7 3.16791 11.9 3.26791L13.8 4.76791C14 4.86791 14.2 4.86791 14.4 4.76791L16 3.56791V15.6679C16 15.9679 15.8 16.1679 15.5 16.1679H2.5ZM10.1 10.168H5C4.4 10.168 4 9.76797 4 9.16797C4 8.56797 4.4 8.16797 5 8.16797H10.1C10.7 8.16797 11.1 8.56797 11.1 9.16797C11.1 9.76797 10.6 10.168 10.1 10.168ZM5 14.168H13.1C13.6 14.168 14.1 13.768 14.1 13.168C14.1 12.568 13.7 12.168 13.1 12.168H5C4.4 12.168 4 12.568 4 13.168C4 13.768 4.4 14.168 5 14.168Z"
        fill="#3DC3E2"
      />
    </svg>
  );
}

function user() {
  return (
    <svg
      className="header__authenticated-btn-icon"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="header__authenticated-btn-icon-path"
        d="M17.9034 16.5C16.803 14.4 15.0023 12.8 12.8014 11.9C14.4021 10.7 15.5025 8.8 15.5025 6.5C15.5025 3 12.6014 0 9 0C5.39863 0 2.49753 3 2.49753 6.6C2.49753 8.8 3.59794 10.8 5.19855 12C3.09775 12.9 1.19703 14.5 0.0966113 16.6C-0.103465 17 -0.00342675 17.6 0.496764 17.9C0.996954 18.1 1.59718 18 1.8973 17.5C3.29783 14.8 5.99886 13.2 9 13.2C12.0011 13.2 14.7022 14.8 16.1027 17.5C16.3028 17.8 16.6029 18 17.003 18C17.2031 18 17.3032 18 17.5032 17.9C18.0034 17.6 18.1035 17 17.9034 16.5ZM4.49829 6.6C4.49829 4.1 6.49905 2 9 2C11.501 2 13.5017 4.1 13.5017 6.6C13.5017 9.1 11.501 11.2 9 11.2C6.49905 11.2 4.49829 9.1 4.49829 6.6Z"
        fill="#90969F"
      />
    </svg>
  );
}

function logo() {
  return (
    <svg className="header__logo-img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.96 36">
      <title>Comparium logo</title>
      <g>
        <g>
          <path
            fill="#3dc3e2"
            d="M4.84,31.12a.78.78,0,0,1,0-1.11L25.69,9.16a.8.8,0,0,1,1.11,0,.81.81,0,0,1,0,1.12L6,31.12A.78.78,0,0,1,4.84,31.12Z"
          />
          <path
            fill="#3dc3e2"
            d="M16,6.58a2,2,0,0,0-.3,1.82c.22.54.75.39,1.11-.05a1.86,1.86,0,0,0,.33-1.83C16.91,6.08,16.36,6,16,6.58Z"
          />
          <path
            fill="#3dc3e2"
            d="M9.79,22.84,7.48,16l5.2-7.36,2.86,8.43,1.24-1.24-3-8.78L17,2.5l3.31,9.78,1.24-1.23L18,.53A.78.78,0,0,0,17.39,0a.79.79,0,0,0-.76.32L6,15.44a.75.75,0,0,0-.1.71l2.68,7.92Z"
          />
          <path
            fill="#3dc3e2"
            d="M36,18.58a.76.76,0,0,0-.52-.63L24.91,14.38l-1.24,1.24,9.79,3.32-4.57,3.23-8.79-3-1.24,1.23,8.44,2.86-7.36,5.19-6.82-2.3-1.24,1.24,7.94,2.68a.77.77,0,0,0,.7-.11L35.63,19.33A.77.77,0,0,0,36,18.58Z"
          />
          <path fill="#3dc3e2" d="M8.11,26.74,8,26.83A.38.38,0,0,0,8.11,26.74Z" />
          <path
            fill="#3dc3e2"
            d="M7.3,25.33,2.85,23.81l3.39-1.65-.5-1.5L.44,23.23A.8.8,0,0,0,0,24a.83.83,0,0,0,.23.51.83.83,0,0,0,.3.18l5.53,1.89Z"
          />
          <path
            fill="#3dc3e2"
            d="M12.77,35.56l2.58-5.32-1.5-.5-1.66,3.41-1.54-4.5L9.41,29.89l1.91,5.58A.76.76,0,0,0,12,36,.8.8,0,0,0,12.77,35.56Z"
          />
        </g>
      </g>
    </svg>
  );
}

function logout() {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0H2.5C1.1 0 0 1.1 0 2.5V13.5C0 14.9 1.1 16 2.5 16H8C9.4 16 10.5 14.9 10.5 13.5V11.7C10.5 11.1 10.1 10.7 9.5 10.7C8.9 10.7 8.5 11.1 8.5 11.7V13.5C8.5 13.8 8.3 14 8 14H2.5C2.2 14 2 13.8 2 13.5V2.5C2 2.2 2.2 2 2.5 2H8C8.3 2 8.5 2.2 8.5 2.5V4.3C8.5 4.9 8.9 5.3 9.5 5.3C10.1 5.3 10.5 4.9 10.5 4.3V2.5C10.5 1.1 9.4 0 8 0Z"
        fill="#FAB342"
      />
      <path
        d="M13.7008 3.70001C13.4008 3.70001 13.2008 3.80001 13.0008 4.00001C12.6008 4.40001 12.6008 5.00001 13.0008 5.40001L14.6008 7.00001H6.10078H5.80078C5.20078 7.00001 4.80078 7.40001 4.80078 8.00001C4.80078 8.60001 5.20078 9.00001 5.80078 9.00001H6.10078H14.6008L13.0008 10.6C12.6008 11 12.6008 11.6 13.0008 12C13.2008 12.2 13.5008 12.3 13.7008 12.3C13.9008 12.3 14.2008 12.2 14.4008 12L17.7008 8.70001C17.8008 8.60001 17.9008 8.50001 17.9008 8.40001C18.0008 8.20001 18.0008 7.90001 17.9008 7.60001C17.8008 7.50001 17.8008 7.40001 17.7008 7.30001L14.4008 4.00001C14.2008 3.80001 13.9008 3.70001 13.7008 3.70001Z"
        fill="#FAB342"
      />
    </svg>
  );
}

function upgrage() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM10 18C5.6 18 2 14.4 2 10C2 5.6 5.6 2 10 2C14.4 2 18 5.6 18 10C18 14.4 14.4 18 10 18Z"
        fill="#3DC3E2"
      />
      <path
        d="M10.7 5.10001C10.6 5.00001 10.5 5.00001 10.4 4.90001C10.2 4.80001 9.90003 4.80001 9.60003 4.90001C9.50003 5.00001 9.40002 5.00001 9.30002 5.10001L5.70002 8.70001C5.30002 9.10001 5.30002 9.70001 5.70002 10.1C5.90002 10.3 6.20002 10.4 6.40002 10.4C6.60002 10.4 6.90002 10.3 7.10002 10.1L9.00002 8.20001V14.5C9.00002 15.1 9.40002 15.5 10 15.5C10.6 15.5 11 15.1 11 14.5V8.20001L12.9 10.1C13.1 10.3 13.4 10.4 13.6 10.4C13.8 10.4 14.1 10.3 14.3 10.1C14.7 9.70001 14.7 9.10001 14.3 8.70001L10.7 5.10001Z"
        fill="#3DC3E2"
      />
    </svg>
  );
}
