import { CheckboxConfig } from './../commonTypes';
import {
  FillConfigsNoUrlAction,
  CheckConfigNoUrlAction,
  CheckAllConfigsNoUrlAction,
  CheckBrowserGroupConfigsNoUrlAction,
  CheckOSGroupConfigsNoUrlAction,
  DisableConfigsNoUrlAction,
  EnableAllConfigsNoUrlAction,
  EnableConfigsNoUrlAction,
} from './types';

export const configsCheckboxesNoURLActions = {
  fillConfigs(config: CheckboxConfig[]): FillConfigsNoUrlAction {
    return { type: 'FILL_CONFIGS_NO_URL', payload: config };
  },
  checkConfig(config: { id: string; checked: boolean }): CheckConfigNoUrlAction {
    return { type: 'CHECK_CONFIG_NO_URL', payload: config };
  },
  checkAllConfigs(): CheckAllConfigsNoUrlAction {
    return { type: 'CHECK_ALL_CONFIGS_NO_URL' };
  },
  checkBrowserGroupConfigs(target: string): CheckBrowserGroupConfigsNoUrlAction {
    return { type: 'CHECK_BROWSER_GROUP_CONFIGS_NO_URL', payload: target };
  },
  checkOSGroupConfigs(target: string): CheckOSGroupConfigsNoUrlAction {
    return { type: 'CHECK_OS_GROUP_CONFIGS_NO_URL', payload: target };
  },
  disableConfigs(browsers: string[]): DisableConfigsNoUrlAction {
    return { type: 'DISABLE_CONFIGS_NO_URL', payload: browsers };
  },
  enableAllConfigs(): EnableAllConfigsNoUrlAction {
    return { type: 'ENABLE_ALL_CONFIGS_NO_URL' };
  },
  enableConfigs(browsers: string[]): EnableConfigsNoUrlAction {
    return { type: 'ENABLE_CONFIGS_NO_URL', payload: browsers };
  },
};
