// core
import React, { useState, useEffect, useRef, ReactElement } from 'react';
import cx from 'classnames';
// styles
import classes from './checkbox.module.css';
// images
import Icons from '../../../../../../images';

export default function Checkbox({ isChecked, setIsChecked }): ReactElement {
  // compute styles
  const iconClasses = cx(classes.icon, { [classes.checked]: isChecked });

  function handleChange() {
    setIsChecked((prevState) => !prevState);
  }

  return (
    <label className={classes.checkbox}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        className={classes.input}
      />
      <span className={iconClasses}>{isChecked && <Icons.checkIconBold />}</span>
      Don’t show this again
    </label>
  );
}
