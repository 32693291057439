import limitOfLivetesting from './limitoflivetestingsessions.svg';
import limitOfParallelTesting from './limitOfParallelTesting.svg';
import limitOfResolutions from './limitOfResolutions.svg';
import limitOfScreenshotsPerMonth from './limitofscreenspermonth.svg';
import limitOfTakenScreenshots from './limitOfTakenScreenshots.svg';
import loginProfile from './loginProfile.svg';

export default {
  limitOfLivetesting,
  limitOfParallelTesting,
  limitOfResolutions,
  limitOfScreenshotsPerMonth,
  limitOfTakenScreenshots,
  loginProfile,
};
