// core
import React, { useEffect, useRef, ReactElement } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// instruments
import { setCookie } from '../../helpers';
// styles
import './whatIsNewReminder.scss';
// images
import images from './images';
import Icons from '../../images';
// typescript types
type Props = { setReminderActive: Function };

function WhatIsNewReminder({ setReminderActive }: Props): ReactElement {
  // hooks
  const ref = useRef(null);

  useEffect(() => {
    function hide(event: MouseEvent) {
      if (!ref.current.contains(event.target)) close();
    }

    document.addEventListener('click', hide);

    return () => document.removeEventListener('click', hide);
  }, []);

  function close() {
    setReminderActive(false);
    setCookie('comparium_whatIsNewReminder', 1);
  }

  return (
    <div className="what-is-new-reminder popup-overlay">
      <div className="what-is-new-reminder__window popup" ref={ref}>
        <Slider {...sliderSettings}>
          {sliderContent.map((item, index) => (
            <div key={index} className="what-is-new-reminder__content">
              <div className="what-is-new-reminder__content-background">
                <img src={images[`image${index + 1}`]} alt="" />
              </div>
              <div className="what-is-new-reminder__content-header">
                <p className="what-is-new-reminder__content-header-date">{item.date}</p>
                <h3 className="what-is-new-reminder__content-header-sub-title">{item.subTitle}</h3>
              </div>
              <h2 className="what-is-new-reminder__content-title">{item.title}</h2>
              <p className="what-is-new-reminder__content-text">{item.text}</p>
              <button
                className="what-is-new-reminder__content-button"
                title="Continue"
                onClick={close}
              >
                CONTINUE
              </button>
              <p className="what-is-new-reminder__content-release-history">
                See full{' '}
                <a
                  href="https://comparium.app/changelog.html"
                  className="what-is-new-reminder__content-release-history-link"
                >
                  release notes
                </a>
              </p>
            </div>
          ))}
        </Slider>
        <button className="what-is-new-reminder__close" title="Close" onClick={close}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.6164 0.292893C15.2259 -0.0976314 14.5927 -0.0976308 14.2022 0.292893L7.95467 6.54045L1.70711 0.292894C1.31658 -0.0976298 0.683417 -0.0976298 0.292893 0.292894C-0.0976314 0.683419 -0.0976308 1.31658 0.292893 1.70711L6.54045 7.95467L0.292911 14.2022C-0.0976137 14.5927 -0.0976137 15.2259 0.292911 15.6164C0.683435 16.0069 1.3166 16.0069 1.70712 15.6164L7.95467 9.36888L14.2022 15.6164C14.5927 16.0069 15.2259 16.0069 15.6164 15.6164C16.0069 15.2259 16.0069 14.5927 15.6164 14.2022L9.36888 7.95467L15.6164 1.70711C16.007 1.31658 16.007 0.683417 15.6164 0.292893Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default WhatIsNewReminder;

function NextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <button
      className={className}
      style={{
        ...style,
        height: '100%',
        top: 0,
        width: '170px',
        left: '100%',
        right: 'auto',
        transform: 'none',
      }}
      onClick={onClick}
      type="button"
    >
      <Icons.arrowNextBig />
    </button>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;

  return (
    <button
      className={className}
      style={{
        ...style,
        height: '100%',
        top: 0,
        width: '170px',
        right: '100%',
        left: 'auto',
        transform: 'none',
      }}
      onClick={onClick}
      type="button"
    >
      <Icons.arrowPrevBig />
    </button>
  );
}

const sliderSettings = {
  dots: true,
  speed: 0,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const sliderContent = [
  {
    date: 'Jun 14, 2021',
    subTitle: 'New feature is out!',
    title: 'Meet Smartscroll',
    text: (
      <span>
        Be able to capture screenshots of the entire pages without any floating elements like pop-up
        ads, GDPR notification window, etc. Manage screenshots and have a complete analysis of your
        webpage.
      </span>
    ),
  },
  {
    date: 'May 24, 2021',
    subTitle: 'New feature is out!',
    title: (
      <span>
        Meet Comparium <br /> Chrome Extension
      </span>
    ),
    text: (
      <span>
        Check our Comparium Chrome <br />
        Extension and have easy web testing. <br />
        Also available for Chromium-based <br /> browsers, like Opera, Edge, etc. <br />
        <a
          target="_blank"
          href="https://chrome.google.com/webstore/detail/comparium-ultimate-cross/kldibjjclnnkcfihijhbmajpamjfcjaj?hl=en"
          className="what-is-new-reminder__content-text-link"
        >
          Learn more &gt;
        </a>
      </span>
    ),
  },
  {
    date: 'Feb 24, 2021',
    subTitle: 'New update is out!',
    title: 'Live testing with updated browsers',
    text: (
      <span>
        The latest available browser versions of Chrome, Opera, and Firefox are now supported in the
        Live testing tool of Comparium. Start testing today!
      </span>
    ),
  },
  {
    date: 'Dec 02, 2020',
    subTitle: 'New feature is out!',
    title: 'Meet Login Profile',
    text: (
      <span>
        Access web pages behind the <br />
        form-based login. Specify the URL, <br />
        username, and password in the <br />
        Login Profile section. Be able <br />
        to capture screenshots <br />
        as the authorized user.
      </span>
    ),
  },
];
