import { CheckboxConfig } from './../commonTypes';
import { ConfigsCheckboxesNoURLActionTypes } from './types';
import {
  checkAllConfigsReducer,
  checkBrowserGroupConfigsReducer,
  checkOsGroupConfigsReducer,
} from '../../../helpers';

export function configsCheckboxesNoURLReducer(
  state: CheckboxConfig[] = [],
  action: ConfigsCheckboxesNoURLActionTypes
): CheckboxConfig[] {
  const newState = [...state];

  switch (action.type) {
    case 'FILL_CONFIGS_NO_URL':
      const fullCopyOfState: CheckboxConfig[] = JSON.parse(JSON.stringify(action.payload));
      return fullCopyOfState;

    case 'CHECK_CONFIG_NO_URL':
      const index = newState.findIndex((item) => item.id === action.payload.id);

      newState[index].checked = action.payload.checked;

      return newState;

    case 'CHECK_ALL_CONFIGS_NO_URL':
      return checkAllConfigsReducer(newState);

    case 'CHECK_BROWSER_GROUP_CONFIGS_NO_URL':
      return checkBrowserGroupConfigsReducer(action.payload, newState);

    case 'CHECK_OS_GROUP_CONFIGS_NO_URL':
      return checkOsGroupConfigsReducer(action.payload, newState);

    case 'DISABLE_CONFIGS_NO_URL':
      return newState.map((item) =>
        action.payload.includes(item.browser) ? { ...item, disabled: true } : item
      );

    case 'ENABLE_ALL_CONFIGS_NO_URL':
      return newState.map((item) => (item.disabled ? { ...item, disabled: false } : item));

    case 'ENABLE_CONFIGS_NO_URL':
      return newState.map((item) =>
        action.payload.includes(item.browser) ? { ...item, disabled: false } : item
      );

    default:
      return state;
  }
}
