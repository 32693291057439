// core
import React, { useEffect, useRef, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
import { screensActions } from '../../store/domains/screenshots/actions';
import { configsCheckboxesWithURLActions } from '../../store/domains/configsCheckboxesWithURL/actions';
// instruments
import useTranslations from '../../hooks/useTranslations';
import { /*convertIntExpToie,*/ defineOsTitle, defineBrowserTitle } from '../../helpers';
import { useAuth0 } from '../../helpers/auth';
//default settings
import { app_metadata_namespace } from '../../settings.json';
//socket
import { _socket as socket } from '../../socket';
// styles
import './dialog.scss';
// typescript types
import { RootState } from '../../store/rootReducer';

function Dialog(): ReactElement {
  // hooks
  const dispatch = useDispatch();
  const dialogRef = useRef(null);
  const screens = useSelector((state: RootState) => state.screens);
  const screenNumber = useSelector((state: RootState) => state.ui.screenNumber);
  const isModalActive = useSelector((state: RootState) => state.ui.isModalActive);
  const screenConfig = useSelector((state: RootState) => state.ui.screenConfigForDeletion);
  const selectionStatus = useSelector((state: RootState) => state.ui.massSelectionStatus);
  const sessionId = useSelector((state: RootState) => state.ui.screensSessionId); // id of the current screenshots session - we need it when we send request to the server screens which were deleted on the front-end side so server will not waste resources to create these screens
  const { areYouSureYouWantToDeleteConfiguration } = useTranslations();
  const { user } = useAuth0();

  // compute os and browser names
  const os = defineOsTitle(screenConfig.os);
  const browser = defineBrowserTitle(screenConfig.browser);

  // find out if we have to delete selected screens or the current screen
  const deletionCondition = selectionStatus && !isModalActive;

  useEffect(() => {
    function hidePanel(event: MouseEvent): void {
      if (!dialogRef.current.contains(event.target)) {
        dispatch(uiActions.closeDialog());
      }
    }

    document.addEventListener('click', hidePanel);

    return () => document.removeEventListener('click', hidePanel);
  }, []);

  function no(): void {
    dispatch(uiActions.closeDialog());
  }

  function yes(): void {
    const newScreenNumber = screenNumber - 1 >= 0 ? screenNumber - 1 : 0;
    const userId = user?.sub;
    const {
      license_product: licenseProduct,
      billing_operator: billingOperator,
      last_order_reference: lastOrderReference,
    } = user?.[app_metadata_namespace]?.comparium?.subscription || {};

    dispatch(uiActions.setScreenNumber(newScreenNumber));

    // if "mass selection" status is enabled and screen preview modal window is not active -
    // delete selected screens, otherwise delete the current screen
    if (deletionCondition) {
      // screensChecked - all checked screens, screensUnchecked - all unchecked screens
      const [screensChecked, screensUnchecked] = screens.reduce(
        (accumulator, currentValue) => {
          if (currentValue.checked) {
            accumulator[0].push(currentValue);
          } else {
            accumulator[1].push(currentValue);
          }

          return accumulator;
        },
        [[], []]
      );

      dispatch(screensActions.deleteCheckedScreens(screensUnchecked));
      dispatch(uiActions.toggleSelectionStatus(false));

      // screenIdsToUncheck are configs which will be unchecked
      // screenIdsToSendToServer is array of IDs of screens which will be send
      // to the server so these screens will not be created and the server resources
      // will not be used. We need to send to the server only those screens which have not been
      // created yet
      const [screenIdsToUncheck, screenIdsToSendToServer] = screensChecked.reduce(
        (accumulator, { screenId, screen }) => {
          accumulator[0].push(screenId);

          if (screen === undefined) {
            accumulator[1].push(screenId);
          }

          return accumulator;
        },
        [[], []]
      );

      dispatch(configsCheckboxesWithURLActions.uncheckConfigs(screenIdsToUncheck));

      if (screenIdsToSendToServer.length > 0) {
        const screenId = screenIdsToSendToServer;
        socket.emit('stopScreenProcessing', { sessionId, screenId, userId, licenseProduct });

        const params = { userId, licenseProduct, billingOperator, lastOrderReference };

        setTimeout(() => socket.emit('getUserData', params), 2000); // send request to get user stats and limits in 2 second in order to make sure that we get actual stats and limits
      }
    } else {
      dispatch(screensActions.deleteScreen(screenConfig.screenId));
      dispatch(
        configsCheckboxesWithURLActions.checkConfig({
          id: `${screenConfig.os}_${
            screenConfig.browser /*convertIntExpToie(screenConfig.browser)*/
          }_${screenConfig.version}`,
          checked: false,
        })
      );

      const screenId = [screenConfig.screenId];

      // check if screen with this id is still not created. If not, we send request to server not to create it so we will decrease load on the server resources
      const isScreenStillNotCreated = screens.find(
        ({ screenId, screen }) => screenId === screenConfig.screenId && screen === undefined
      );

      if (isScreenStillNotCreated) {
        socket.emit('stopScreenProcessing', { sessionId, screenId, userId, licenseProduct });

        const params = { userId, licenseProduct, billingOperator, lastOrderReference };

        setTimeout(() => socket.emit('getUserData', params), 2000); // send request to get user stats and limits in 2 second in order to make sure that we get actual stats and limits
      }
    }

    document.body.style.overflow = ''; //enable scroll
    dispatch(uiActions.closeDialog());
  }

  return (
    <div className="dialog popup-overlay">
      <div className="dialog__content popup" ref={dialogRef}>
        {deletionCondition ? (
          <p className="dialog__text">Are you sure you want to delete selected screenshot(s)?</p>
        ) : (
          <p className="dialog__text">
            {areYouSureYouWantToDeleteConfiguration}
            <br />
            {`${os} / ${browser} ${screenConfig.version}`}
          </p>
        )}
        <div className="dialog__buttons">
          <button className="dialog__button" onClick={no}>
            NO
          </button>
          <button className="dialog__button" onClick={yes}>
            YES
          </button>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Dialog);
