// core
import React, { ReactElement } from 'react';
// styles
import './alert.scss';
// typescript types
type Props = { backgroundColor: string; text: ReactElement; strongText?: ReactElement };

function Alert({ backgroundColor, text, strongText }: Props): ReactElement {
  // CSS background color
  const style = { backgroundColor };

  return (
    <div className="alert" style={style}>
      <p className="alert__text">
        {strongText && <strong>{strongText}</strong>} {text}
      </p>
    </div>
  );
}

export default React.memo(Alert);
