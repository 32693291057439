import { Profile, LoginProfilesState, LoginProfilesActionsTypes } from './types';

const initialState: LoginProfilesState = {
  currentOperationWithProfile: { operation: 'create', profileID: null },
  //currentOperationWithProfile: { operation: 'edit', profileID: 135 },
  profileForDeletion: null,
  profileNotSaved: null,
  profiles: [],
};

export function loginProfilesReducer(
  state = initialState,
  action: LoginProfilesActionsTypes
): LoginProfilesState {
  let profiles: Profile[];

  switch (action.type) {
    case 'FILL_LOGIN_PROFILES':
      return { ...state, profiles: [...action.payload] };

    case 'SELECT_LOGIN_PROFILE':
      profiles = state.profiles.map((item) =>
        item.id === action.payload
          ? { ...item, selected: !item.selected }
          : { ...item, selected: false }
      );

      return { ...state, profiles };

    case 'UNSELECT_LOGIN_PROFILE':
      profiles = state.profiles.map((item) => ({ ...item, selected: false }));

      return { ...state, profiles };

    case 'CREATE_LOGIN_PROFILE':
      return { ...state, profiles: [...state.profiles, { ...action.payload }] };

    case 'EDIT_LOGIN_PROFILE':
      profiles = state.profiles.map((profile) =>
        profile.id === action.payload.id ? { ...action.payload } : profile
      );

      return { ...state, profiles };

    case 'MARK_LOGIN_PROFILE_FOR_EDITING':
      return { ...state, currentOperationWithProfile: { ...action.payload } };

    case 'DELETE_LOGIN_PROFILE':
      profiles = state.profiles.filter((item) => item.id !== action.payload);

      return { ...state, profiles };

    case 'SET_LOGIN_PROFILE_FOR_DELETION':
      return { ...state, profileForDeletion: action.payload };

    case 'SET_LOGIN_PROFILE_NOT_SAVED':
      return { ...state, profileNotSaved: { ...action.payload } };

    case 'CLEAR_LOGIN_PROFILE_NOT_SAVED':
      return { ...state, profileNotSaved: null };

    default:
      return state;
  }
}
