import {
  UpdateCheckedScreens,
  ScreensActionsTypes,
  FillScreensAction,
  SetScreenAction,
  AddScreensAction,
  DeleteScreenAction,
  UpdateScreenAction,
  UpdateScreensAction,
  UpdateCheckedScreensAction,
  ToggleCheckScreenAction,
  CheckAllScreensAction,
  DeleteCheckedScreensAction,
} from './types';
import { ScreenConfig } from '../commonTypes';

export const screensActions = {
  fillScreens(data: ScreenConfig[]): FillScreensAction {
    return { type: 'FILL_SCREENS', payload: data };
  },
  setScreen(data: ScreenConfig): SetScreenAction {
    return { type: 'SET_SCREEN', payload: data };
  },
  addScreens(data: ScreenConfig[]): AddScreensAction {
    return { type: 'ADD_SCREENS', payload: data };
  },
  deleteScreen(data: string): DeleteScreenAction {
    return { type: 'DELETE_SCREEN', payload: data };
  },
  updateScreen(data: string): UpdateScreenAction {
    return { type: 'UPDATE_SCREEN', payload: data };
  },
  updateScreens(data: ScreenConfig[]): UpdateScreensAction {
    return { type: 'UPDATE_SCREENS', payload: data };
  },
  deleteCheckedScreens(screensUnchecked: ScreenConfig[]): DeleteCheckedScreensAction {
    return { type: 'DELETE_CHECKED_SCREENS', payload: screensUnchecked };
  },
  updateCheckedScreens(data: UpdateCheckedScreens): UpdateCheckedScreensAction {
    return { type: 'UPDATE_CHECKED_SCREENS', payload: data };
  },
  toggleCheckScreen(data: ScreenConfig): ToggleCheckScreenAction {
    return { type: 'TOGGLE_CHECK_SCREEN', payload: data };
  },
  checkAllScreens(filter: boolean | number): CheckAllScreensAction {
    return { type: 'CHECK_ALL_SCREENS', payload: filter };
  },
  uncheckAllScreens(): ScreensActionsTypes {
    return { type: 'UNCHECK_ALL_SCREENS' };
  },
};

export type ScreensActionsType = typeof screensActions;
