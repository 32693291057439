// core
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
import { screensActions } from '../../store/domains/screenshots/actions';
// instruments
import {
  defineOsTitle,
  defineOsBigIcon,
  defineBrowserBigIcon,
  defineBrowserTitle,
  sendScreensToServer,
  isScreenAvailable,
  // generateUniqueId,
  // generateHashForVncSession,
} from '../../helpers';
import { useAuth0 } from '../../helpers/auth';
import useTranslations from '../../hooks/useTranslations';
//import { api } from '../../api';
//socket
import { _socket as socket } from '../../socket';
//default settings
import { app_metadata_namespace } from '../../settings.json';
// images
import images from '../../images/screenStatus';
import Icons from '../../images';
// styles
import './modal.scss';
// typescript types
import { RootState } from '../../store/rootReducer';

function Modal() {
  // hooks
  const dispatch = useDispatch();
  const isModalActive = useSelector((state: RootState) => state.ui.isModalActive);
  const number = useSelector((state: RootState) => state.ui.screenNumber);
  const isDialogActive = useSelector((state: RootState) => state.ui.isDialogActive);
  const url = useSelector((state: RootState) => state.ui.url);
  const allScreens = useSelector((state: RootState) => state.screens);
  const filteredScreens = useSelector((state: RootState) => state.resolutions.filteredScreens);
  const credentials = useSelector((state: RootState) => state.ui.credentialsForBasicAuth);
  const profiles = useSelector((state: RootState) => state.loginProfiles.profiles);
  const userLimitsAndStats = useSelector((state: RootState) => state.userLimitsAndStats);
  const screensSessionId = useSelector((state: RootState) => state.ui.screensSessionId); // id of the current screenshots session - we need it in order to add new screenshots for creation and not update stats of screens created per month
  const isSmartScrollOn = useSelector((state: RootState) => state.ui.isSmartScrollOn);
  const [loginProfileId, setLoginProfileId] = useState(); // id of selected login profile
  //const isDesktop = useMediaQuery({ minWidth: 769 }); // if screen width is more than 768px
  const {
    placedURL,
    width,
    oops,
    refreshScreenshot,
    deleteScreenshot,
    previous,
    next,
  } = useTranslations();
  const { user } = useAuth0();
  const userId = user?.sub;

  // find out if the URL is longer than 22 symbols
  const withDots = url.length > 22;

  // compute CSS classes
  const urlClasses = cx('modal__header-url', { withDots: withDots });

  // define screens to display in modal window
  const screens = filteredScreens.length > 0 ? filteredScreens : allScreens;

  // get info about active screenshot in the modal window
  const os = screens[number].os;
  const browser = screens[number].browser;
  const version = screens[number].version;
  const resolution = screens[number].resolution;
  const osIcon = defineOsBigIcon(os);
  const browserIcon = defineBrowserBigIcon(browser);
  const browserTitle = defineBrowserTitle(browser);
  const osTitle = defineOsTitle(os);
  const screenId = screens[number].screenId;
  const thisScreenConfig = { os, browser, version, resolution, screenId }; // current screen config

  // array of DOM nodes of slider images
  const sliderImages: HTMLDivElement[] = [];

  useEffect(() => {
    showCurrentSlide(sliderImages); // display current slide
  });

  useEffect(() => {
    function hideModalOnEsc(event: KeyboardEvent): void {
      if (
        isModalActive &&
        !isDialogActive &&
        (event.keyCode || event.which || event.charCode) === 27
      ) {
        dispatch(uiActions.closeModal());
        document.body.style.overflow = ''; //enable scroll
      }
    }

    document.addEventListener('keydown', hideModalOnEsc);

    return () => {
      document.removeEventListener('keydown', hideModalOnEsc);
    };
  }, [isModalActive, isDialogActive]);

  // find out if one of the login profiles is selected and get its ID
  useEffect(() => {
    const selectedProfile = profiles.find((profile) => profile.selected);

    setLoginProfileId(selectedProfile?.id);
  }, [profiles]);

  function showCurrentSlide(sliderImages: HTMLDivElement[]): void {
    sliderImages.forEach((image) => {
      image.classList.remove('active');
      image.classList.remove('enter-active');
    });

    sliderImages[number].classList.add('enter-active');

    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        sliderImages[number].classList.add('active');
      });
    });
  }

  function prevClick(): void {
    if (screens.length > 1) {
      const newNumber = number - 1 < 0 ? sliderImages.length - 1 : number - 1;

      dispatch(uiActions.setScreenNumber(newNumber));
    }
  }

  function nextClick(): void {
    if (screens.length > 1) {
      const newNumber = number + 1 >= sliderImages.length ? 0 : number + 1;

      dispatch(uiActions.setScreenNumber(newNumber));
    }
  }

  function closeModal(): void {
    document.body.style.overflow = ''; //enable scroll
    dispatch(uiActions.closeModal());
  }

  function deleteScreen(): void {
    dispatch(uiActions.setScreenConfigForDeletion(thisScreenConfig));
    dispatch(uiActions.showDialog());
  }

  function updateScreen(): void {
    dispatch(screensActions.updateScreen(screenId));

    const { areScreensUnlimited, isScreensLimitReached } = userLimitsAndStats;

    // check if the user has reached the limit of screenshots per month
    if (isScreensLimitReached && !areScreensUnlimited) {
      dispatch(uiActions.toggleDemoreminder('limitOfScreenshotsPerMonth'));
      return;
    }

    const { user_auth, user_pass } = credentials;
    const {
      license_product: licenseProduct,
      billing_operator: billingOperator,
      last_order_reference: lastOrderReference,
    } = user?.[app_metadata_namespace]?.comparium?.subscription || {};

    sendScreensToServer({
      url,
      socket,
      configs: [thisScreenConfig],
      userId,
      noCache: true,
      user_auth,
      user_pass,
      loginProfileId,
      sessionId: screensSessionId,
      licenseProduct,
      billingOperator,
      lastOrderReference,
      isSmartScrollOn,
    });
  }

  // async function handleRunLiveTestClick() {
  //   const { isLiveSessionsLimitReached, areLiveSessionsUnlimited } = userLimitsAndStats;

  //   if (isLiveSessionsLimitReached && !areLiveSessionsUnlimited) {
  //     dispatch(uiActions.toggleDemoreminder('limitOfLivetesting'));
  //     return;
  //   }

  //   let isParallelSessionsLimitReached;
  //   const {
  //     license_product: licenseProduct,
  //     billing_operator: billingOperator,
  //     last_order_reference: lastOrderReference,
  //   } = user?.[app_metadata_namespace]?.comparium?.subscription || {};

  //   try {
  //     const request = await api.checkIfParallelSessionsLimitReached(userId, licenseProduct);
  //     isParallelSessionsLimitReached = !request?.data; // if data is equal to true, that means that limit of parallel sessions was not reached
  //   } catch (error) {
  //     console.log('error of getting parallel sessions limit', error);
  //   }

  //   if (isParallelSessionsLimitReached) {
  //     dispatch(uiActions.toggleDemoreminder('limitOfParallelTesting'));

  //     const params = { userId, licenseProduct, billingOperator, lastOrderReference };

  //     socket.emit('getUserData', params);

  //     return;
  //   }

  //   const vncSessionId = generateHashForVncSession();
  //   const configForRealTimeTesting = {
  //     os,
  //     browser,
  //     version,
  //     urlForVncSession: url,
  //     userId,
  //     vncSessionId,
  //   };

  //   dispatch(uiActions.setConfigForRealTimeTesting(configForRealTimeTesting));
  //   dispatch(uiActions.toggleRealTimeTestingPreloader(true));
  //   closeModal();
  // }

  const imagesListJsx = screens.map(({ screen }, index) => {
    const screenStatus = isScreenAvailable(screen);

    return (
      <div key={index} className="modal__img" ref={(node) => sliderImages.push(node)} tabIndex={0}>
        {screenStatus === 'available' && <img src={screen} alt="" className="modal__img-item" />}
        {screenStatus === 'loading' && (
          <div className="modal__img-loading">
            <img src={images.loading} alt="" className="modal__img-loading-img" />
          </div>
        )}
        {screenStatus === 'error' && (
          <div className="modal__img-error">
            <img src={images.error} alt="" className="modal__img-error-img" />
            <p className="modal__img-error-message">{oops}</p>
          </div>
        )}
      </div>
    );
  });

  return (
    <div className="modal">
      <header className="modal__header">
        <button
          className="modal__header-delete square-btn"
          onClick={deleteScreen}
          title={deleteScreenshot}
        >
          <Icons.deleteIcon />
        </button>
        <button
          className="modal__header-update square-btn"
          disabled={!screens[number].screen}
          onClick={updateScreen}
          title={refreshScreenshot}
        >
          <Icons.update />
        </button>
        <p className={urlClasses} title={url}>
          {placedURL}: <br /> {url}
        </p>
        <p className="modal__header-resolution">
          {width} <br /> {resolution}&nbsp;px
        </p>
        <div className="modal__header-slider-buttons">
          <button className="modal__header-prev square-btn" onClick={prevClick} title={previous}>
            <Icons.arrowPrev />
          </button>
          <div className="modal__header-counter">
            <span>{number + 1}</span>
            <span className="modal__header-counter-separator">/</span>
            <span>{screens.length}</span>
          </div>
          <button className="modal__header-next square-btn" onClick={nextClick} title={next}>
            <Icons.arrowNext />
          </button>
        </div>
        <div className="modal__versions">
          <div className="modal__versions-os" title={osTitle}>
            <img className="modal__versions-img" src={osIcon} alt={osTitle} />
            <span className="modal__versions-os-name">{osTitle}</span>
          </div>
          <div className="modal__versions-browser" title={`${browserTitle} ${version}`}>
            <img className="modal__versions-img" src={browserIcon} alt={browserTitle} />
            <span className="modal__versions-browser-name">{browserTitle}&nbsp;</span>
            <span>{version}</span>
          </div>
        </div>
        {/*isDesktop && ['LINUX', '10WINDOWS'].includes(os) && !['90.0', '91.0'].includes(version) && (
          <button
            className="modal__header-run-live-test orange-btn oval-btn"
            onClick={handleRunLiveTestClick}
            title="Run live test"
          >
            Run live test
          </button>
				)*/}
        <button className="modal__header-close square-btn" onClick={closeModal}>
          <Icons.cross />
        </button>
      </header>
      <div className="modal__images" aria-label="Gallery">
        {imagesListJsx}
      </div>
    </div>
  );
}

export default React.memo(Modal);
