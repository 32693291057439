// core
import React, { FC } from 'react';
// styles
import './preloader.scss';

const Preloader: FC = () => {
  return (
    <div className="preloader">
      <div className="preloader__item">
        <div className="preloader__item-rolling">
          <div className="preloader__item-rolling-circle"></div>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
