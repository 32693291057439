// core
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
// instruments
import { useAuth0 } from '../../helpers/auth';
// images
import Icons from '../../images';
import images from './images';
// styles
import './demoreminder.scss';
// typescript types
import { RootState } from '../../store/rootReducer';
import { DemoreminderType } from '../../types';
type LimitsType = {
  vncLimit: number;
  screensLimit: number;
  parallelsLimit: number;
  parallelsCount: number;
};

function DemoReminder() {
  // hooks
  const dispatch = useDispatch();
  const demoreminderStatus = useSelector((state: RootState) => state.ui.isDemoreminderActive);
  const userLimitsAndStats = useSelector((state: RootState) => state.userLimitsAndStats);
  const userDoesntHavePlan = useSelector((state: RootState) => state.ui.userDoesntHavePlan);
  const demoreminderRef = useRef(null);

  useEffect(() => {
    function hide(event: MouseEvent): void {
      if (demoreminderStatus && !demoreminderRef.current.contains(event.target)) {
        dispatch(uiActions.toggleDemoreminder(false));
      }
    }

    document.addEventListener('click', hide);

    return () => {
      document.removeEventListener('click', hide);
    };
  }, [demoreminderStatus]);

  // dextructuring user limits and stats data
  const {
    license_subscription_vnc_count: vncLimit,
    license_subscription_screens_count: screensLimit,
    license_session_parallels_count: parallelsLimit,
    count_parallel_tests_for_now: parallelsCount,
  } = userLimitsAndStats;
  const limits: LimitsType = { vncLimit, screensLimit, parallelsLimit, parallelsCount };

  function close(): void {
    dispatch(uiActions.toggleDemoreminder(false));
  }

  function navigateToPricingTab(event) {
    event.preventDefault();
    dispatch(uiActions.toggleDemoreminder(false));
    dispatch(uiActions.toggleAccountPageTabsValue(1));
    navigate('/account');
  }

  const title = defineTitle(demoreminderStatus);
  const text = defineText(demoreminderStatus, limits, userDoesntHavePlan);

  return (
    <div className="demoreminder">
      <div className="demoreminder__window" ref={demoreminderRef}>
        <button className="demoreminder__close-btn" onClick={close}>
          <Icons.cross />
        </button>
        <img src={images[demoreminderStatus]} alt="" className="demoreminder__logo" />
        <h2 className="demoreminder__title">{title}</h2>
        <p className="demoreminder__text">{text}</p>
        {userDoesntHavePlan ? (
          <>
            <button
              className="demoreminder__upgrade-btn orange-gradient-btn two-border-active"
              onClick={navigateToPricingTab}
            >
              Upgrade
            </button>
            <button className="demoreminder__later-btn" onClick={close}>
              Later
            </button>
          </>
        ) : (
          <button
            className="demoreminder__upgrade-btn orange-gradient-btn two-border-active"
            onClick={close}
          >
            Ok
          </button>
        )}
      </div>
    </div>
  );
}

export default React.memo(DemoReminder);

function defineTitle(demoreminderStatus: DemoreminderType) {
  switch (demoreminderStatus) {
    case 'limitOfLivetesting':
      return 'The limit of live testing per month has been reached.';

    case 'limitOfParallelTesting':
      return 'The limit of parallel testing has been reached.';

    case 'limitOfResolutions':
      return 'Resolutions';

    case 'limitOfScreenshotsPerMonth':
      return 'The limit of screenshots per month has been reached.';

    case 'limitOfTakenScreenshots':
      return 'Screenshots';

    case 'loginProfile':
      return 'Login Profile';

    default:
      return null;
  }
}

function defineText(
  demoreminderStatus: DemoreminderType,
  limits: LimitsType,
  userDoesntHavePlan: boolean
) {
  const { vncLimit, screensLimit, parallelsLimit, parallelsCount } = limits;

  switch (demoreminderStatus) {
    case 'limitOfLivetesting':
      if (userDoesntHavePlan) {
        return `You have already used all ${vncLimit} live-sessions this month. The limit will be unblocked next month or you can upgrade now to continue running live tests.`;
      } else {
        return `You have already used all ${vncLimit} live-sessions this month. The limit will be unblocked next month.`;
      }

    case 'limitOfParallelTesting':
      if (userDoesntHavePlan) {
        return `${parallelsCount} of ${parallelsLimit} parallel sessions are currently active. Please wait until current session is finished or upgrade now to run more than ${parallelsLimit} session at a time.`;
      } else if (parallelsLimit === 10) {
        return `${parallelsCount} of ${parallelsLimit} parallel sessions are currently active. Please wait until the current sessions are finished or contact our Sales Department to help you with this issue.`;
      } else {
        return `${parallelsCount} of ${parallelsLimit} parallel sessions are currently active. Please wait until current session is finished.`;
      }

    case 'limitOfScreenshotsPerMonth':
      return `You have already taken ${screensLimit} screenshots this month. The limit of screenshots will be renewed next month or you can upgrade now to continue taking screenshots.`;

    case 'limitOfResolutions':
      return `Testing several resolutions at a time is available in a paid version of Comparium.`;

    case 'limitOfTakenScreenshots':
      return `Want to increase the number of taken screenshots? Get an unlimited number with the paid plan of Comparium.`;

    case 'loginProfile':
      return `Want to take screenshots as an authorized user? Reveal this functionality in the paid plan.`;

    default:
      return null;
  }
}
