// core
import React, { useEffect, useRef, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { navigate } from 'gatsby';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
// images
import Icons from '../../images';
// styles
import './thanksForPurchase.scss';

function ThanksForPurchase(): ReactElement {
  // hooks
  const dispatch = useDispatch();
  const thanksForPurchaseRef = useRef(null);

  useEffect(() => {
    function hide(event: MouseEvent): void {
      if (!thanksForPurchaseRef.current.contains(event.target)) {
        close();
      }
    }

    document.addEventListener('click', hide);

    return () => document.removeEventListener('click', hide);
  }, []);

  function close(): void {
    window.sessionStorage.removeItem('compariumSessionID');
    dispatch(uiActions.toggleThanksForPurchasePopup(false));
    dispatch(uiActions.toggleThanksForPurchaseAlert(true));
    navigate('/');
  }

  return (
    <div className="thanksForPurchase">
      <div className="thanksForPurchase__content" ref={thanksForPurchaseRef}>
        <button className="thanksForPurchase__close-btn" onClick={close}>
          <Icons.cross />
        </button>
        <h2 className="thanksForPurchase__title">You’re almost there!</h2>
        <p className="thanksForPurchase__text">
          Thank you for the purchase. It may take a few minutes to process your payment and activate
          your Comparium PRO subscription plan.
        </p>
        <div className="thanksForPurchase__btn-block">
          <button className="thanksForPurchase__btn-block-btn btn blue-btn" onClick={close}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
}

export default ThanksForPurchase;
