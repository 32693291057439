import { createLogger } from 'redux-logger';
import { applyMiddleware } from 'redux';

const logger = createLogger({
  duration: true,
  collapsed: true,
  colors: {
    title: () => '#139BFE',
    prevState: () => '#1C5FAF',
    action: () => '#149945',
    nextState: () => '#A47104',
    error: () => '#FF0005',
  },
});

const middleware = [];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

const enhancedStore = applyMiddleware(...middleware);

export { enhancedStore };
