// core
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
import { configsCheckboxesWithURLActions } from '../../store/domains/configsCheckboxesWithURL/actions';
import { configsCheckboxesNoURLActions } from '../../store/domains/configsCheckboxesNoURL/actions';
// styles
import './checkboxConfig.scss';
// typescript types
import { RootState } from '../../store/rootReducer';

type Props = {
  id: string;
  os: string;
  browser: string;
  version: string;
  checked: boolean;
  disabled: boolean;
  mayBeAlreadyChecked: boolean;
  massSelectHover: boolean | string;
  areAllItemsInThisGroupChecked: boolean;
};

function CheckboxConfig(props: Props) {
  // hooks
  const dispatch = useDispatch();
  const checkboxesWithURL = useSelector((state: RootState) => state.configsCheckboxesWithURL); // all checkboxes which are used to create new screens (not to add new screens)
  const checkboxesNoURL = useSelector((state: RootState) => state.configsCheckboxesNoURL); // all checkboxes which are used to add new screens
  const userLimitsAndStats = useSelector((state: RootState) => state.userLimitsAndStats);
  const resolutions = useSelector((state: RootState) => state.resolutions.resolutions);

  // props destructuring
  const {
    id,
    os,
    browser,
    version,
    checked,
    disabled,
    mayBeAlreadyChecked,
    massSelectHover,
    areAllItemsInThisGroupChecked,
  } = props;

  const {
    areScreensUnlimited,
    license_subscription_screens_count: screensLimit,
    count_screens_for_period: screensCount,
    license_session_screens_count: allowedNumberOfCheckedCheckboxes,
    areCheckedConfigsUnlimitted,
  } = userLimitsAndStats;

  // actions destructuring
  const { checkConfig: checkConfigWithURL } = configsCheckboxesWithURLActions;
  const { checkConfig: checkConfigNoURL } = configsCheckboxesNoURLActions;

  // compute CSS classes
  const classes = cx(
    'check-config',
    { checked },
    { disabled },
    {
      'mass-select-hover':
        (areAllItemsInThisGroupChecked &&
          (massSelectHover === 'all' || massSelectHover === browser || massSelectHover === os)) ||
        (!checked &&
          (massSelectHover === 'all' || massSelectHover === browser || massSelectHover === os)),
    }
  );

  function handleChange() {
    const numberOfCheckedCheckboxesWithURL = checkboxesWithURL.filter((item) => item.checked)
      .length;
    const numberOfCheckedCheckboxesNoURL = checkboxesNoURL.filter((item) => item.checked).length;
    const numberOfCheckedResolutions = resolutions.filter((item) => item.checked).length;

    // check if user is allowed to check one more screens
    if (!areScreensUnlimited && !checked) {
      let numberOfExpectedScreensWithURL, numberOfExpectedScreensNoURL;
      const numberOfAllowedScreens = screensLimit - screensCount;

      if (mayBeAlreadyChecked) {
        numberOfExpectedScreensWithURL =
          (numberOfCheckedCheckboxesWithURL + 1) * numberOfCheckedResolutions;

        if (numberOfExpectedScreensWithURL > numberOfAllowedScreens) {
          dispatch(uiActions.toggleDemoreminder('limitOfScreenshotsPerMonth'));
          return;
        }
      } else {
        numberOfExpectedScreensNoURL = numberOfCheckedCheckboxesNoURL + 1;

        if (numberOfExpectedScreensNoURL > numberOfAllowedScreens) {
          dispatch(uiActions.toggleDemoreminder('limitOfScreenshotsPerMonth'));
          return;
        }
      }
    }

    if (!areCheckedConfigsUnlimitted) {
      if (checked) {
        if (mayBeAlreadyChecked) dispatch(checkConfigWithURL({ id, checked: false }));
        else dispatch(checkConfigNoURL({ id, checked: false }));
      } else if (numberOfCheckedCheckboxesWithURL >= allowedNumberOfCheckedCheckboxes) {
        dispatch(uiActions.toggleDemoreminder('limitOfTakenScreenshots'));
      } else if (mayBeAlreadyChecked) {
        dispatch(checkConfigWithURL({ id, checked: true }));
      } else if (
        numberOfCheckedCheckboxesWithURL + numberOfCheckedCheckboxesNoURL >=
        allowedNumberOfCheckedCheckboxes
      ) {
        dispatch(uiActions.toggleDemoreminder('limitOfTakenScreenshots'));
      } else {
        dispatch(checkConfigNoURL({ id, checked: true }));
      }
    } else if (mayBeAlreadyChecked) {
      dispatch(checkConfigWithURL({ id, checked: !checked }));
    } else {
      dispatch(checkConfigNoURL({ id, checked: !checked }));
    }
  }

  return (
    <label className={classes}>
      {version}
      <input
        checked={checked}
        onChange={handleChange}
        type="checkbox"
        className="check-config__checkbox"
        disabled={disabled}
      />
    </label>
  );
}

export default React.memo(CheckboxConfig);
