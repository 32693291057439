import React from 'react';
import Helmet from 'react-helmet';

function SEO() {
  const title = 'Test URL to see whether it meets cross-browser compatibility';
  const description =
    'Check how your webpage will be displayed in different browsers and operating systems.';
  const url = 'https://front.comparium.app';
  const keywords = [
    'cross-browser testing',
    'test site in different browsers',
    'test URL in different browsers',
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: 'keywords',
          content: keywords.join(', '),
        },

        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: '/og-image.png',
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ]}
      link={[
        {
          rel: 'shortcut icon',
          href: '/favicon.ico',
          type: 'image/x-icon',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          href: '/apple-touch-icon-144x144-precomposed.png',
          sizes: '144x144',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          href: '/apple-touch-icon-114x114-precomposed.png',
          sizes: '114x114',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          href: '/apple-touch-icon-72x72-precomposed.png',
          sizes: '72x72',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          href: '/apple-touch-57x57-icon-precomposed.png',
          sizes: '57x57',
        },
        {
          rel: 'apple-touch-icon',
          href: '/touch-icon-iphone.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '76x76',
          href: '/touch-icon-ipad.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '120x120',
          href: '/touch-icon-iphone-retina.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '152x152',
          href: '/touch-icon-ipad-retina.png',
        },
      ]}
    />
  );
}

export default React.memo(SEO);
