// core
import React, { useState, useEffect, useRef, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import ReCaptcha from 'react-google-recaptcha';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
// instruments
import {
  defineOsTitleForVnc,
  defineOsBigIconForVnc,
  defineBrowserBigIcon,
  defineBrowserTitle,
  dataLayerPush,
} from '../../helpers';
import { useAuth0 } from '../../helpers/auth';
//default settings
import { recaptchaSitekey, app_metadata_namespace } from '../../settings.json';
//socket
import { _socket as socket } from '../../socket';
// styles
import './realTimeTestingPreloader.scss';
// typescript types
import { RootState } from '../../store/rootReducer';
// images
import Icons from '../../images';

const listOfProgressbarItems = [
  'Setting up a secure connection to remote server',
  'Launching selected operating system',
  'Opening requested browser',
  'Loading specified URL',
  'Starting Live session',
];

function RealTimeTestingPreloader(): ReactElement {
  // hooks
  const dispatch = useDispatch();
  const { os, browser, version, urlForVncSession, userId, vncSessionId } = useSelector(
    (state: RootState) => state.ui.configForRealTimeTesting
  );
  const [numberOfActiveListItem, setNumberOfActiveListItem] = useState(0);
  const recaptchaRef = useRef();
  const { user } = useAuth0();
  const isWindows = os === '10WINDOWS'; // find out if we are running live testing for Windows 10 platform

  useEffect(() => {
    let recaptchaToken: string;
    const {
      license_recaptcha,
      license_product: licenseProduct,
      billing_operator: billingOperator,
      last_order_reference: lastOrderReference,
    } = user?.[app_metadata_namespace]?.comparium?.subscription || {};

    if (license_recaptcha === undefined || license_recaptcha === '1') {
      enableCaptcha();
    } else {
      sendRequest();
    }

    async function enableCaptcha() {
      try {
        recaptchaToken = await recaptchaRef.current.executeAsync();

        sendRequest();
      } catch (error) {
        console.error('recaptcha error', error);

        dispatch(uiActions.toggleRealTimeTestingPreloader(false));
      }
    }

    function sendRequest() {
      const openVncConnectionParams = {
        os,
        browser,
        version,
        url: urlForVncSession,
        userId,
        hash: vncSessionId,
        recaptchaToken,
        licenseProduct,
        billingOperator,
        lastOrderReference,
      };
      const getUserDataParams = { userId, licenseProduct, billingOperator, lastOrderReference };

      socket.emit('openVncConnection', openVncConnectionParams);
      setTimeout(() => socket.emit('getUserData', getUserDataParams), 2000); // send request to get user stats and limits in 2 second in order to make sure that we get actual stats and limits
      sendDataAboutLiveTestingToGTM(os, browser, version);
    }
  }, []);

  useEffect(() => {
    // execute this effect only for Windows platform
    if (isWindows) {
      function timer() {
        setNumberOfActiveListItem(numberOfActiveListItem + 1);
      }

      // If 'numberOfActiveListItem' variable is less than 4, we set a timer for 8 second.
      // When that time has elapsed, 'numberOfActiveListItem' variable will be updated.
      if (numberOfActiveListItem < 4) setTimeout(timer, 8000);
    }
  }, [numberOfActiveListItem]);

  const osIcon = defineOsBigIconForVnc(os);
  const browserIcon = defineBrowserBigIcon(browser);

  function handleCloseClick() {
    dispatch(uiActions.toggleRealTimeTestingPreloader(false));

    // send request to cancel creation of VNC session which hasn't started yet
    socket.emit('cancelVncSession', { hash: vncSessionId });
  }

  // creating list of 'progressbar' items
  let list: ReactElement;

  if (isWindows) {
    const listItems = listOfProgressbarItems.map((item, index) => {
      const classes = cx('realTimeTestingPreloader__progress-item-icon', {
        active: index === numberOfActiveListItem,
        complete: index < numberOfActiveListItem,
      });

      return (
        <li key={index} className={'realTimeTestingPreloader__progress-item'}>
          <span className={classes}>
            <Icons.checkIconBold />
          </span>
          {item}
        </li>
      );
    });

    list = <ul className="realTimeTestingPreloader__progress">{listItems}</ul>;
  } else list = null;

  return (
    <div className="realTimeTestingPreloader">
      <ReCaptcha ref={recaptchaRef} size="invisible" sitekey={recaptchaSitekey} />
      <div className="realTimeTestingPreloader__content">
        <div className="realTimeTestingPreloader__loader">
          <div className="realTimeTestingPreloader__loader-box left">
            <span className="realTimeTestingPreloader__loader-circle">
              <img src={osIcon} alt="" className="realTimeTestingPreloader__loader-img" />
            </span>
          </div>
          <div className="realTimeTestingPreloader__loader-box right">
            <span className="realTimeTestingPreloader__loader-circle">
              <img src={browserIcon} alt="" className="realTimeTestingPreloader__loader-img" />
            </span>
          </div>
        </div>
        <p className="realTimeTestingPreloader__text">
          Loading {defineBrowserTitle(browser)} {version} on {defineOsTitleForVnc(os)}
        </p>
        {list}
        <button
          className="realTimeTestingPreloader__btn white-blue-btn"
          onClick={handleCloseClick}
          title="Cancel"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default React.memo(RealTimeTestingPreloader);

function sendDataAboutLiveTestingToGTM(os: string, browser: string, version: string): void {
  // send info about number of each OS to Google Tag Manager
  const platformsParams = {
    event: 'customEvent',
    eventCategory: 'info',
    eventAction: 'platforms_vnc',
    eventLabel: os,
    eventValue: 1,
  };

  dataLayerPush(platformsParams);

  // send info about number of each browser to Google Tag Manager
  const browsersParams = {
    event: 'customEvent',
    eventCategory: 'info',
    eventAction: 'browsers_vnc',
    eventLabel: browser,
    eventValue: 1,
  };

  dataLayerPush(browsersParams);

  // send info about configs to Google Tag Manager
  const capabilities = `${os}_${browser}_${version}`;
  const capabilitiesParams = {
    event: 'customEvent',
    eventCategory: 'info',
    eventAction: 'capabilities_vnc',
    eventLabel: capabilities,
    eventValue: 1,
  };

  dataLayerPush(capabilitiesParams);
}
