import {
  Profile,
  CurrentOperation,
  LoginProfilesActionsTypes,
  SelectProfileAction,
  CreateProfileAction,
  EditProfileAction,
  MarkProfileForEditingAction,
  DeleteProfileAction,
  SetProfileForDeletionAction,
  SetProfileNotSavedAction,
  FillProfilesAction,
} from './types';

export const loginProfilesActions = {
  fillProfiles(profiles: Profile[]): FillProfilesAction {
    return {
      type: 'FILL_LOGIN_PROFILES',
      payload: profiles,
    };
  },
  selectProfile(id: number): SelectProfileAction {
    return {
      type: 'SELECT_LOGIN_PROFILE',
      payload: id,
    };
  },
  unselectProfile(): LoginProfilesActionsTypes {
    return { type: 'UNSELECT_LOGIN_PROFILE' };
  },
  createProfile(profile: Profile): CreateProfileAction {
    return {
      type: 'CREATE_LOGIN_PROFILE',
      payload: profile,
    };
  },
  editProfile(profile: Profile): EditProfileAction {
    return {
      type: 'EDIT_LOGIN_PROFILE',
      payload: profile,
    };
  },
  markProfileForEditing(data: CurrentOperation): MarkProfileForEditingAction {
    return {
      type: 'MARK_LOGIN_PROFILE_FOR_EDITING',
      payload: data,
    };
  },
  deleteProfile(id: number): DeleteProfileAction {
    return {
      type: 'DELETE_LOGIN_PROFILE',
      payload: id,
    };
  },
  setProfileForDeletion(id: number): SetProfileForDeletionAction {
    return {
      type: 'SET_LOGIN_PROFILE_FOR_DELETION',
      payload: id,
    };
  },
  setProfileNotSaved(profile: Profile): SetProfileNotSavedAction {
    return {
      type: 'SET_LOGIN_PROFILE_NOT_SAVED',
      payload: profile,
    };
  },
  clearProfileNotSaved(): LoginProfilesActionsTypes {
    return { type: 'CLEAR_LOGIN_PROFILE_NOT_SAVED' };
  },
};
