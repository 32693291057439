// core
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
// instruments
import { useAuth0 } from '../../helpers/auth';
import { trackingConversionForPaddlePurchase, disableScroll } from '../../helpers';
//default settings
import { checkoutOperator, linkApiForCreatingPaddletrialLink } from '../../settings.json';
// images
import Icons from '../../images';
// styles
import './continueWithTrial.scss';
// typescript types
type Props = { setIsContinueWithTrialActive: Function };

function ContinueWithTrial({ setIsContinueWithTrialActive }: Props): ReactElement {
  // hooks
  const dispatch = useDispatch();
  const { user, setIsTrial } = useAuth0();

  function handleClick() {
    const product_id = checkoutOperator.paddle.trial;
    const user_id = user?.sub;
    const user_email = user?.email;
    const data = { product_id, user_id, user_email };
    const postData = new FormData();
    postData.append('data', JSON.stringify(data));

    axios
      .post(linkApiForCreatingPaddletrialLink, postData)
      .then((data) => {
        if (data.data?.success) {
          const override = data.data.response.url;
          const email = user?.email;

          if (override) {
            Paddle.Checkout.open({ override, email, successCallback, closeCallback });
            disableScroll(true);
          }
        } else {
          setIsTrial(false);
          setIsContinueWithTrialActive(false);
        }
      })
      .catch((error) => console.error('error after attempt to get trial paylink', error));
  }

  function closeCallback() {
    disableScroll(false);
  }

  function successCallback(data) {
    dispatch(uiActions.toggleThanksForPurchasePopup(true));
    setIsContinueWithTrialActive(false);
    disableScroll(false);

    Paddle.Order.details(data?.checkout?.id, (data) => trackingConversionForPaddlePurchase(data));
  }

  return (
    <div className="continue-with-trial popup-overlay">
      <img src={Icons.logo} alt="Comparium" className="continue-with-trial__logo" />
      <p className="continue-with-trial__text important">
        <strong>
          Be aware that during the 7-day trial you will be using Comparium absolutely for free. The
          checkout process is for authorization only.
        </strong>
      </p>
      <p className="continue-with-trial__text">
        If you wish to continue using Comparium on the 8th day, a fee of $15/month will be
        automatically charged to use the service without interruption.
      </p>
      <p className="continue-with-trial__text">
        During the free trial period, you can cancel the purchase of a new subscription without
        losing a cent. A reminder will be sent to your email before your free trial expires.
      </p>
      <button className="continue-with-trial__btn" onClick={handleClick}>
        Continue with trial
      </button>
    </div>
  );
}

export default ContinueWithTrial;
