// core
import React, { useState, useEffect, useRef, ReactElement } from 'react';
// components
import Checkbox from './components/checkbox';
import { getCookie, setCookie } from '../../../../helpers';
// images
import Images from './images';
// styles
import './englishLayoutAlert.scss';

export default function EnglishLayoutAlert({ setIsActive }): ReactElement {
  // hooks
  const [isChecked, setIsChecked] = useState(false);

  function handleClick() {
    const isCookieSet = getCookie('englishLayoutAlert_already_displayed'); // check if the cookie 'englishLayoutAlert_already_displayed' is set

    setIsActive(false);
    if (isChecked && !isCookieSet) setCookie('englishLayoutAlert_already_displayed', 1);
  }

  return (
    <div className="englishLayoutAlert">
      <Images.englishLanguage />
      <div className="englishLayoutAlert__content">
        <p className="englishLayoutAlert__heading">English layout is required</p>
        <p className="englishLayoutAlert__text">
          Please make sure the English layout is enabled while typing in a search box.
        </p>
        <div className="englishLayoutAlert__controls">
          <Checkbox isChecked={isChecked} setIsChecked={setIsChecked} />
          <button onClick={handleClick} className="englishLayoutAlert__btn">
            Ok
          </button>
        </div>
      </div>
    </div>
  );
}
