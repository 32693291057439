// core
import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { decompress as decompressString } from 'shrink-string';
// components
import Checkbox from '../components/checkboxConfig';
//default settings
import { defaultResolution, GTM, app_metadata_namespace } from '../settings.json';
// images
import osBigIcons from '../images/osBigIcons';
import browserBigIcons from '../images/browserBigIcons';
// typescript types
import { ResolutionsType, RawConfigsCheckboxes, RawConfigsLivetesting } from '../types';
import { CheckboxConfig, ScreenConfig, OsBrVer } from '../store/domains/commonTypes';
type queryData = {
  url: string;
  configs: ScreenConfig[];
  resolutions: number[];
  isQueryParamFromExtension: boolean;
};
import { UiActionsType } from '../store/domains/ui/actions';
import { ScreensActionsType } from '../store/domains/screenshots/actions';
import { configsCheckboxesWithURLActionsType } from '../store/domains/configsCheckboxesWithURL/actions';
import { ScreenOption, ScreenOptgroup, ModeOption, MockupType } from '../components/compare/types';

// array of all possible configs as strings like 'OS_browser_version'
export function getConfigsCheckboxesAsStrings(configs: CheckboxConfig[]): string[] {
  return configs.map(({ os, browser, version }) => `${os}_${browser}_${version}`);
}

// array of all possible configs as objects like {os, browser, version}
export function createConfigsCheckboxes(configs: RawConfigsCheckboxes): CheckboxConfig[] {
  const possibleConfigsAsObjects: CheckboxConfig[] = [];

  for (let os in configs) {
    for (let browser in configs[os]) {
      configs[os][browser].forEach((version: string) => {
        //const convertedBrowser = convertIEtoIntExp(browser);

        possibleConfigsAsObjects.push({
          id: `${os}_${browser}_${version}`,
          os,
          browser,
          version,
          checked: false,
          disabled: false,
        });
      });
    }
  }

  return possibleConfigsAsObjects;
}

export function createConfigsLivetesting(configs: RawConfigsLivetesting): OsBrVer[] {
  const arrayOfLivetestingConfigs: OsBrVer[] = [];

  for (let os in configs) {
    for (let browser in configs[os]) {
      configs[os][browser].forEach((version: string) => {
        //const convertedBrowser = convertIEtoIntExp(browser);

        arrayOfLivetestingConfigs.push({ os, browser, version });
      });
    }
  }

  return arrayOfLivetestingConfigs;
}

// define big OS icon
export function defineOsBigIcon(platform: string): string {
  switch (platform) {
    case 'OSX':
      return osBigIcons.mac;

    case '10WINDOWS':
      return osBigIcons.windows10Blue;

    case 'LINUX':
      return osBigIcons.linux;
  }
}

// define big OS icon
export function defineOsBigIconForVnc(platform: string): string {
  switch (platform) {
    case 'OSX':
      return osBigIcons.mac;

    case '10WINDOWS':
      return osBigIcons.windows10Blue;

    case 'LINUX':
      return osBigIcons.linux;
  }
}

// define big browser icon
export function defineBrowserBigIcon(browser: string): string {
  switch (browser) {
    case 'firefox':
      return browserBigIcons.firefox;

    case 'chrome':
      return browserBigIcons.chrome;

    case 'safari':
      return browserBigIcons.safari;

    case 'MicrosoftEdge':
      return browserBigIcons.edge;
  }
}

// define disabled browser icon
export function defineBrowserIconDisabled(browser: string): string {
  switch (browser) {
    case 'firefox':
      return browserBigIcons.firefoxDisabled;

    case 'chrome':
      return browserBigIcons.chromeDisabled;

    case 'safari':
      return browserBigIcons.safariDisabled;
  }
}

// define disabled browser icon not active
export function defineBrowserIconNotActive(browser: string): string {
  switch (browser) {
    case 'firefox':
      return browserBigIcons.firefoxNotActive;

    case 'chrome':
      return browserBigIcons.chromeNotActive;

    case 'safari':
      return browserBigIcons.safariNotActive;
  }
}

// define browser title
export function defineBrowserTitle(browser: string): string {
  switch (browser) {
    case 'firefox':
      return 'Firefox';

    case 'chrome':
      return 'Chrome';

    case 'safari':
      return 'Safari';

    case 'MicrosoftEdge':
      return 'Edge';

    default:
      return browser;
  }
}

// define os title
export function defineOsTitle(os: string): string {
  switch (os) {
    case 'OSX':
      return 'macOS';

    case '10WINDOWS':
      return 'Windows 10';

    case 'LINUX':
      return 'Linux';
  }
}

// define os title for VNC (real time testing) - we have different configs for VNC
export function defineOsTitleForVnc(os: string): string {
  switch (os) {
    case 'OSX':
      return 'macOS';

    case '10WINDOWS':
      return 'Windows 10';

    case 'LINUX':
      return 'Linux';
  }
}

// define os title (mobile version)
export function defineOsTitleMobile(os: string): string {
  switch (os) {
    case 'OSX':
      return 'macOS';

    case '10WINDOWS':
      return 'Win10';

    case 'LINUX':
      return 'Linux';
  }
}

// convert all letters of string to lowercase except the first letter
export function upperCaseFirstLetter(string: string): string {
  return string
    ?.split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

// check if we got screenshot from the server, if not, put preloader
export function isScreenAvailable(screen: undefined | string): string {
  switch (screen) {
    case undefined:
      return 'loading';

    case 'error':
      return 'error';

    default:
      return 'available';
  }
}

// scroll page to top on mobile device with small height
export function scrollToTop(): void {
  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;
}

function computeOsBrVersResol(item: string[]): ScreenConfig {
  if (item[1] !== 'DARK') {
    const os = item[0];
    const browser = item[1]; /*convertIEtoIntExp(item[1])*/
    const version = item[2];
    const resolution = item[3] ? +item[3] : defaultResolution;
    const screenId = `${os}_${item[1]}_${version}_${resolution}`;

    return { os, browser, version, resolution, screenId };
  } else {
    const os = `${item[0]}_${item[1]}`;
    const browser = item[2];
    const version = item[3];
    const resolution = item[4] ? +item[4] : defaultResolution;
    const screenId = `${os}_${browser}_${version}_${resolution}`;

    return { os, browser, version, resolution, screenId };
  }
}

// parse query (GET) parameters of the URL
export async function getQueryVariables(
  possibleConfigsAsStrings: string[],
  availableResolutions: number[]
): Promise<queryData | false> {
  let query = window.location.search.substring(1);
  const isBrowserParameter = query.includes('browser');
  const isCompressedQueryParamsParameter = query.includes('compressed_query_params');

  if (!isBrowserParameter && !isCompressedQueryParamsParameter) {
    return false;
  }

  // if the URL includes 'compressed_query_params' query parameter we need to decompress its value so we can get query parameters
  if (isCompressedQueryParamsParameter) {
    const queryParamValue = query.substring('compressed_query_params='.length); // we need to remove 'compressed_query_params=' from the query parameters string because it was not compressed
    query = await decompressString(queryParamValue); // we need to decompress query parameters string because they were compressed in order to make the URL shorter
  }

  let configs: ScreenConfig[];
  let resolutions: number[] = [];
  let customResolution: number;
  const pairs = query.split('&').map((pair) => pair.split('='));
  let [[, url]] = pairs.filter((pair) => pair[0] === 'url');

  configs = pairs
    .filter(
      (pair) =>
        pair[0] === 'browser' && possibleConfigsAsStrings.some((item) => pair[1].includes(item))
    ) // filter only 'browser' query parameters and only those parameters which meet our configs
    .map((pair) => pair[1]) // getting config value
    .filter((item, index, self) => self.indexOf(item) === index) // getting only unique values
    .map((item) => computeOsBrVersResol(item.split('_'))); // creating each config in object format

  // creating 'set' of available resolutions
  const availableResolutionsSet = new Set();
  availableResolutions.forEach((resolution) => availableResolutionsSet.add(resolution));

  // assign the first resolution which is not in the 'availableResolutions' array to the variable 'customResolution'
  for (let i = 0; i < configs.length; i++) {
    if (!availableResolutionsSet.has(configs[i].resolution)) {
      customResolution = configs[i].resolution;
      break;
    }
  }

  // filter all configs which have resolution which is not in the 'availableResolutions' array or doesn't equal to 'customResolution' variable
  configs = configs.filter(
    (config) =>
      availableResolutionsSet.has(config.resolution) || config.resolution === customResolution
  );

  // filling all resolutions which we have in configs
  configs.forEach(({ resolution }) => {
    if (!resolutions.includes(resolution)) resolutions.push(resolution);
  });

  url = window.decodeURIComponent(url);

  // check if URL includes query parameter create_screens_from_browser_extension
  const isQueryParamFromExtension = query.includes('create_screens_from_browser_extension');

  return { url, configs, resolutions, isQueryParamFromExtension };
}

// convert string 'internet explorer' to 'IE'
// export function convertIntExpToIE(browser: string): string {
//   return browser.toLowerCase() === 'internet explorer' ? 'IE' : browser;
// }

// convert string 'internet explorer' to 'ie'
// export function convertIntExpToie(browser: string): string {
//   return browser.toLowerCase() === 'internet explorer' ? 'ie' : browser;
// }

// convert string 'ie' to 'internet explorer'
// export function convertIEtoIntExp(browser: string): string {
//   return browser === 'ie' ? 'internet explorer' : browser;
// }

// domain validation
export function validateURL(url: string): boolean {
  const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

  if (regexp.test(url)) return true;

  return false;
}

// disable scroll of main application
export function disableScroll(status: boolean): void {
  if (status) document.body.style.overflow = 'hidden';
  else document.body.style.overflow = '';
}

// check if cookie exists
export function getCookie(name: string): false | string {
  let cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const currentCookie = cookies[i].trim();

    if (currentCookie.indexOf(`${name}=`) === 0) {
      return currentCookie.substring(name.length + 1, currentCookie.length);
    }
  }

  return false;
}

// set cookie
export function setCookie(name: string, value: number | string, days?: number) {
  let date = new Date();

  // if 'days' parameter is not provided, we set max expiration date
  if (days) {
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  } else {
    date.setTime(date.getTime() + 472147483647);
  }

  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
}

// This function extracts the value of one variable from the query string.
// If the variable isn't defined in the URL it returns the default value instead.
export function getQueryParameterByName(name: string): string | false {
  // A URL with a query parameter can look like this:
  // https://www.example.com?myqueryparam=myvalue
  // Note that we use location.href instead of location.search
  // because Firefox < 53 has a bug w.r.t location.search
  const re = new RegExp('.*[?&]' + name + '=([^&#]*)');
  const match = document.location.href.match(re);

  if (match) {
    // We have to decode the URL since want the clear text value
    return decodeURIComponent(match[1]);
  }

  return false;
}

export function generateUniqueId() {
  return Math.floor(Math.random() * 10000000);
}

export function generateHashForVncSession() {
  return `${Date.now()}${generateUniqueId()}`;
}

// compute CSS height of 'main' block of page with no alerts
export function computeMainBlockHeight(isMobile: boolean): object {
  const footerHeight = isMobile ? '150px' : '50px';

  return { minHeight: `calc(100vh - 60px - ${footerHeight})` };
}

// compute CSS height of 'main' block of page with alerts on the top of the page
export function computeMainBlockHeightWithAlerts(args: object): object {
  const {
    isXSMobile,
    isThanksForPurchaseAlertActive,
    isPlanActiveAlertActive,
    isPaymentDeatilsUpdatedAlertActive,
    isAppSumoAlertActive,
  } = args;

  const alertHeight =
    isThanksForPurchaseAlertActive ||
    isPlanActiveAlertActive ||
    isPaymentDeatilsUpdatedAlertActive ||
    isAppSumoAlertActive
      ? '40px'
      : '0px';
  const footerHeight = isXSMobile ? '150px' : '50px';

  return { minHeight: `calc(100vh - 60px - ${footerHeight} - ${alertHeight})` };
}

// filter necessary checkboxes
export function filterCheckboxes(
  checkboxes: CheckboxConfig[],
  os: string,
  browser: string,
  mayBeAlreadyChecked: boolean,
  massSelectHover: string | boolean,
  areAllItemsInThisGroupChecked?: boolean
): ReactElement[] {
  return checkboxes
    .filter((item) => item.os === os && item.browser === browser)
    .sort((x, y) => +y.version - +x.version) // checkboxes with higher version must be at the beginning
    .map((item) => (
      <Checkbox
        key={item.version}
        {...item}
        mayBeAlreadyChecked={mayBeAlreadyChecked}
        massSelectHover={massSelectHover}
        areAllItemsInThisGroupChecked={areAllItemsInThisGroupChecked}
      />
    ));
}

// get form data of block with no URL
function getFormDataNoURL(
  formData: CheckboxConfig[],
  currentResolution: number | number[]
): ScreenConfig[] {
  if (currentResolution instanceof Array) {
    let configs = [];

    currentResolution.forEach((resolution) => {
      const configsOfThisResolution = formData.map(({ os, browser, version, id }) => ({
        os,
        browser,
        version,
        checked: false,
        resolution,
        screenId: `${id}_${currentResolution}`,
      }));

      configs = [...configs, ...configsOfThisResolution];
    });

    return configs;
  }

  return formData.map(({ os, browser, version, id }) => ({
    os,
    browser,
    version,
    checked: false,
    resolution: currentResolution,
    screenId: `${id}_${currentResolution}`,
  }));
}

function deleteDuplicates(
  newConfigs: ScreenConfig[],
  currentConfigs: ScreenConfig[]
): { screensToAdd: ScreenConfig[]; screensToUpdate: ScreenConfig[] } {
  const screensToAdd = newConfigs.filter(
    (newConfig) =>
      !currentConfigs.some((currentConfig) => currentConfig.screenId === newConfig.screenId)
  );

  const screensToUpdate = newConfigs.filter((newConfig) =>
    currentConfigs.some((currentConfig) => currentConfig.screenId === newConfig.screenId)
  );

  // return screens which will be added to the other screens and screens which will be updated
  return { screensToAdd, screensToUpdate };
}

export function handleSubmitConfigNoURL(
  url: string,
  socket: SocketIOClient.Socket,
  checkboxes: CheckboxConfig[],
  dispatch: Dispatch<any>,
  screensActions: ScreensActionsType,
  configsCheckboxesWithURLActions: configsCheckboxesWithURLActionsType,
  currentResolution: number | number[],
  currentScreens: ScreenConfig[],
  userId: string,
  user_auth: string,
  user_pass: string,
  loginProfileId: number,
  screensSessionId: string,
  licenseProduct: string,
  billingOperator: string,
  lastOrderReference: string,
  isSmartScrollOn: boolean
) {
  // get info from checked checkboxes
  const checkedCheckboxes = checkboxes.filter((item) => item.checked);

  if (checkedCheckboxes.length > 0) {
    const newScreens = getFormDataNoURL(checkedCheckboxes, currentResolution);
    const { screensToAdd, screensToUpdate } = deleteDuplicates(newScreens, currentScreens);
    const configs = [...screensToAdd, ...screensToUpdate];

    dispatch(screensActions.addScreens(screensToAdd));
    dispatch(configsCheckboxesWithURLActions.checkConfigs(screensToAdd));
    dispatch(screensActions.updateScreens(screensToUpdate));
    sendScreensToServer({
      url,
      socket,
      configs,
      userId,
      user_auth,
      user_pass,
      loginProfileId,
      sessionId: screensSessionId,
      licenseProduct,
      billingOperator,
      lastOrderReference,
      isSmartScrollOn,
    });
  }
}

type DataLayerType = {
  event: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string | number;
  eventValue?: number;
  registration?: { actionField: object };
  ecommerce?: object;
};

// function-wrapper for Google Tag Manager dataLayer.push
export function dataLayerPush(dataLayerParams: DataLayerType): void {
  if (GTM && window.dataLayer != undefined) {
    window.dataLayer.push(dataLayerParams);
  }
}

type SendScreensToServerArgsType = {
  url: string;
  socket: SocketIOClient.Socket;
  configs: ScreenConfig[];
  userId: string;
  noCache?: true;
  user_auth?: string;
  user_pass?: string;
  loginProfileId?: number;
  sessionId?: string;
  licenseProduct: string;
  billingOperator: string;
  lastOrderReference: string;
  isSmartScrollOn?: boolean;
};

export function sendScreensToServer(sendScreensToServerArgs: SendScreensToServerArgsType) {
  let { socket, ...configsToSend } = sendScreensToServerArgs;

  socket.emit('screensRequest', configsToSend);

  const params = {
    userId: configsToSend.userId,
    licenseProduct: configsToSend.licenseProduct,
    billingOperator: configsToSend.billingOperator,
    lastOrderReference: configsToSend.lastOrderReference,
  };

  // send request to get user stats and limits in 2 second in order to make sure that we get actual stats and limits
  setTimeout(() => socket.emit('getUserData', params), 2000);
}

export function findDifferencesForCompareTool(
  mode: ModeOption,
  areBothScreensAvailable: boolean,
  templateScreen: string,
  comparedScreen: string,
  delta: number,
  userId,
  sendScreenToFindDifferences: Function,
  setDisplayStandardScreenPreloader: Function,
  setFindDifferencesImg: Function
) {
  if (mode.label === 'Find Differences') {
    if (areBothScreensAvailable) {
      sendScreenToFindDifferences(templateScreen, comparedScreen, delta, userId);
      return;
    }

    if (comparedScreen === undefined) {
      setDisplayStandardScreenPreloader(true);
      setFindDifferencesImg(undefined);
    }
  }
}

export function createOptionsForCompareTool(
  screens: ScreenConfig[],
  templateScreenValue: ScreenOption,
  comparedScreenValue: ScreenOption,
  setTemplateScreenValue: Function,
  setComparedScreenValue: Function,
  setPlainListOfScreenOptions: Function,
  setComparedScreenOptions: Function,
  setTemplateScreenOptions: Function,
  mockup: MockupType
) {
  // plain array of options
  let plainListOfScreenOptions: ScreenOption[] = [];
  // array which contains groups of options
  const comparedScreenOptions = screens
    .sort((x, y) => y.resolution - x.resolution)
    .reduce((accum: ScreenOptgroup[], { os, browser, version, resolution, screen }) => {
      // get the array index of the option group with the label which contains current screen resolution
      const groupIndex = accum.findIndex((item) => `${resolution} px` === item.label);
      os = defineOsTitle(os);
      //browser = convertIntExpToIE(defineBrowserTitle(browser));
      browser = defineBrowserTitle(browser);
      const label = `${os} / ${browser} ${version}`;
      const singleValueLabel = { resolution, os, browser, version };
      const config = `${os}_${browser}_${version}_${resolution}`;
      const value = { config, screen };
      const template = templateScreenValue?.value.config === value.config;
      const compared = comparedScreenValue?.value.config === value.config;
      const singleOptionToPush: ScreenOption = {
        label,
        singleValueLabel,
        value,
        template,
        compared,
      };

      // if we already have option group with such label, just add an option to this group
      if (groupIndex > -1) {
        accum[groupIndex].options.push(singleOptionToPush);
        plainListOfScreenOptions.push(singleOptionToPush);

        return accum;
      }

      // if there is no option group with such label, create option group with this label
      accum.push({ label: `${resolution} px`, options: [singleOptionToPush] });
      plainListOfScreenOptions.push(singleOptionToPush);

      return accum;
    }, []);

  const isMockupOptionActive = templateScreenValue?.value.config === 'mockup';
  const mockupObject: ScreenOption = {
    label: 'mockup',
    singleValueLabel: 'mockup',
    value: { config: 'mockup', screen: mockup.layoutLink },
    template: isMockupOptionActive,
    compared: false,
    mockupStatus: mockup.mockupStatus,
  };
  const templateScreenOptions: ScreenOptgroup[] = [
    { label: 'Mockup', options: [mockupObject] },
    ...comparedScreenOptions,
  ];

  if (!templateScreenValue && plainListOfScreenOptions?.[0]) {
    setTemplateScreenValue(plainListOfScreenOptions[0]);
  } else if (templateScreenValue?.value.screen === undefined && !isMockupOptionActive) {
    const key = templateScreenValue.value.config;
    const template = plainListOfScreenOptions.find((item) => item.value.config === key);

    if (template?.value.screen !== undefined) {
      setTemplateScreenValue(template);
    }
  }

  if (!comparedScreenValue && plainListOfScreenOptions?.[1]) {
    setComparedScreenValue(plainListOfScreenOptions[1]);
  } else if (comparedScreenValue?.value.screen === undefined) {
    const key = comparedScreenValue.value.config;
    const compared = plainListOfScreenOptions.find((item) => item.value.config === key);

    if (compared.value.screen !== undefined) {
      setComparedScreenValue(compared);
    }
  }

  setPlainListOfScreenOptions(plainListOfScreenOptions);
  setComparedScreenOptions(comparedScreenOptions);
  setTemplateScreenOptions(templateScreenOptions);
}

export function setMockupTemplateScreenValueForCompareTool(
  mockup: MockupType,
  templateScreenValue: ScreenOption,
  setTemplateScreenValue: Function,
  templateScreenOptions: ScreenOptgroup[]
) {
  const newTemplateScreenValue = {
    label: 'mockup',
    singleValueLabel: 'mockup',
    value: { config: 'mockup', screen: mockup.layoutLink },
    template: true,
    compared: false,
    mockupStatus: mockup.mockupStatus,
  };

  if (
    mockup.mockupStatus === 'not uploaded' &&
    templateScreenOptions &&
    templateScreenValue?.value.config === 'mockup'
  ) {
    setTemplateScreenValue(templateScreenOptions[1].options[0]);
  } else if (
    mockup.mockupStatus === 'uploading' ||
    templateScreenValue?.value.config === 'mockup'
  ) {
    setTemplateScreenValue(newTemplateScreenValue);
  }
}

export function isCheked(
  checked: boolean,
  payload: string,
  target: string,
  isAnyNotChecked: boolean,
  disabled: boolean
): boolean {
  if (disabled) return false;
  else return target !== payload ? checked : isAnyNotChecked;
}

export function checkAllConfigsReducer(state: CheckboxConfig[]) {
  const isAnyNotChecked = state.some((item) => !item.checked && !item.disabled);

  if (isAnyNotChecked)
    return state.map((item) => (item.disabled ? item : { ...item, checked: true }));
  else return state.map((item) => (item.disabled ? item : { ...item, checked: false }));
}

export function checkBrowserGroupConfigsReducer(payload: string, state: CheckboxConfig[]) {
  const isAnyBrowserNotChecked = state.some((item) => item.browser === payload && !item.checked);

  return state.map((item) => ({
    ...item,
    checked: isCheked(item.checked, payload, item.browser, isAnyBrowserNotChecked, item.disabled),
  }));
}

export function checkOsGroupConfigsReducer(payload: string, state: CheckboxConfig[]) {
  const isAnyOSNotChecked = state.some(
    (item) => item.os === payload && !item.checked && !item.disabled
  );

  return state.map((item) => ({
    ...item,
    checked: isCheked(item.checked, payload, item.os, isAnyOSNotChecked, item.disabled),
  }));
}

export function trackingConversionForPaddlePurchase(data) {
  let products = [];

  if (data.lockers != null) {
    for (let i = 0; i < data.lockers.length; i++) {
      products.push({
        name: data.lockers[i]?.product_name,
        id: data.lockers[i]?.product_id,
        price: data.order?.total,
        brand: 'Electronic',
        quantity: 1,
      });
    }
  }

  dataLayerPush({ event: 'checkoutSuccess' });

  let balance = +(data.order?.total - data.order?.total_tax).toFixed(2);
  let actionField = {
    id: data.order?.order_id,
    affiliation: 'Electronic Team',
    revenue: data.order?.total,
    tax: data.order?.total_tax,
    coupon: data.order?.coupon_code,
    balance_usd: balance,
    checkout_id: data.checkout?.id,
  };

  dataLayerPush({
    event: 'requestTrial',
    ecommerce: {
      currencyCode: data.order?.currency,
      purchase: { actionField, products },
    },
  });

  return true;
}

export function checkIfUserDoesntHavePlan(user, app_metadata_namespace) {
  const subscription = user?.[app_metadata_namespace]?.comparium?.subscription;
  let { disabled, expired, status, license_type } =
    user?.[app_metadata_namespace]?.comparium?.subscription || {};
  status = status?.toLowerCase();
  license_type = license_type?.toLowerCase();

  return (
    !user?.[app_metadata_namespace] ||
    subscription == null ||
    disabled == 1 ||
    expired == 1 ||
    status === 'pending_activation' ||
    status === 'pastdue' ||
    license_type === 'free'
  );
}

export function trimUploadedMockupFileName(filename) {
  let mockupFileName = filename;

  // on server the file name is modified by adding numbers at the beginning
  // of the file name (in this way "1617369739928_filename.png")
  // we need to remove these numbers. Also, we need to remove '.png'
  // at the end of the name because every uploaded image is converted to png on the server
  const indexOfTheFirst_ = mockupFileName.indexOf('_');

  if (indexOfTheFirst_ >= 0) {
    mockupFileName = mockupFileName.substring(indexOfTheFirst_ + 1);
  }

  const lastIndexOfPng = mockupFileName.lastIndexOf('.png');

  if (lastIndexOfPng >= 0) {
    mockupFileName = mockupFileName.substring(0, lastIndexOfPng);
  }

  return mockupFileName;
}

type CheckIfConfigsCanBeCheckedType = {
  dispatch: Dispatch<any>;
  uiActions: UiActionsType;
  screensLimit: number;
  screensCount: number;
  checkboxes: CheckboxConfig[];
  multiplyByNumberOfResolutions: boolean; // if multiplyByNumberOfResolutions is true that means that this is ConfigDesktopWithURL component and we need to multiply the number of the checked configs by the number of resolutions, otherwise it is ConfigDesktopNoURL component and we don't need to multiply by the number of resolutions
  numberOfCheckedResolutions: number;
};

// before sending request to create screens, check if current number of checked configs (checkboxes) is within the limit
export function checkIfCurrentConfigsCanBeChecked(params: CheckIfConfigsCanBeCheckedType): boolean {
  const {
    dispatch,
    uiActions,
    screensLimit,
    screensCount,
    checkboxes,
    numberOfCheckedResolutions,
    multiplyByNumberOfResolutions,
  } = params;

  const numberOfAllowedScreens = screensLimit - screensCount;
  let numberOfExpectedScreens = checkboxes.filter((item) => item.checked).length;

  if (multiplyByNumberOfResolutions) {
    numberOfExpectedScreens = numberOfExpectedScreens * numberOfCheckedResolutions;
  }

  if (numberOfAllowedScreens < numberOfExpectedScreens) {
    dispatch(uiActions.toggleDemoreminder('limitOfScreenshotsPerMonth'));
    return false;
  }

  return true;
}

export function checkIfAllConfigsCanBeChecked(params: CheckIfConfigsCanBeCheckedType) {
  const {
    dispatch,
    uiActions,
    screensLimit,
    screensCount,
    checkboxes,
    numberOfCheckedResolutions,
    multiplyByNumberOfResolutions,
  } = params;

  let numberOfExpectedScreens = checkboxes.length;
  const numberOfAllowedScreens = screensLimit - screensCount;

  if (multiplyByNumberOfResolutions) {
    numberOfExpectedScreens = checkboxes.length * numberOfCheckedResolutions;
  }

  if (numberOfAllowedScreens < numberOfExpectedScreens) {
    dispatch(uiActions.toggleDemoreminder('limitOfScreenshotsPerMonth'));
    return false;
  }

  return true;
}

type RunLiveTest = {
  socket: SocketIOClient.Socket;
  dispatch: Dispatch<any>;
  uiActions: UiActionsType;
  isLiveSessionsLimitReached: boolean;
  areLiveSessionsUnlimited: boolean;
  user;
  api;
  os: string;
  browser: string;
  version: string;
  url: string;
  setIsPopupActive?: Function;
  setIsRunLiveTestBtnDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
};

export async function runLiveTest(params: RunLiveTest) {
  const {
    socket,
    dispatch,
    uiActions,
    isLiveSessionsLimitReached,
    areLiveSessionsUnlimited,
    user,
    api,
    os,
    browser,
    version,
    url,
    setIsPopupActive,
    setIsRunLiveTestBtnDisabled,
  } = params;

  if (isLiveSessionsLimitReached && !areLiveSessionsUnlimited) {
    dispatch(uiActions.toggleDemoreminder('limitOfLivetesting'));

    if (setIsPopupActive) setIsPopupActive(false);
    if (setIsRunLiveTestBtnDisabled) setIsRunLiveTestBtnDisabled(false);

    return;
  }

  let isParallelSessionsLimitReached: boolean;
  const userId = user?.sub;
  const {
    license_product: licenseProduct,
    billing_operator: billingOperator,
    last_order_reference: lastOrderReference,
  } = user?.[app_metadata_namespace]?.comparium?.subscription || {};

  try {
    const request = await api.checkIfParallelSessionsLimitReached(userId, licenseProduct);
    isParallelSessionsLimitReached = !request?.data; // if data is equal to true, that means that limit of parallel sessions was not reached
  } catch (error) {
    console.log('error of getting parallel sessions limit', error);
  }

  if (isParallelSessionsLimitReached) {
    dispatch(uiActions.toggleDemoreminder('limitOfParallelTesting'));
    if (setIsPopupActive) setIsPopupActive(false);

    const params = { userId, licenseProduct, billingOperator, lastOrderReference };

    socket.emit('getUserData', params);

    if (setIsRunLiveTestBtnDisabled) setIsRunLiveTestBtnDisabled(false);

    return;
  }

  const vncSessionId = generateHashForVncSession();
  const urlForVncSession = window.decodeURIComponent(url);
  const configForRealTimeTesting = {
    os,
    browser,
    version,
    urlForVncSession,
    userId,
    vncSessionId,
  };

  dispatch(uiActions.setConfigForRealTimeTesting(configForRealTimeTesting));
  dispatch(uiActions.toggleRealTimeTestingPreloader(true));
  if (setIsPopupActive) setIsPopupActive(false);
  if (setIsRunLiveTestBtnDisabled) setIsRunLiveTestBtnDisabled(false);
}

export function createScreensConfig(
  resolutions: ResolutionsType[],
  checkedCheckboxes: CheckboxConfig[]
) {
  return resolutions.reduce((accumulator, { checked, value: resolution }) => {
    if (checked) {
      const configsForCurrentResolution = checkedCheckboxes.map(({ os, browser, version, id }) => ({
        os,
        browser,
        version,
        checked: false,
        resolution,
        screenId: `${id}_${resolution}`, // for screen we need to add 'resolution' to 'id' which we get from checkbox object
      }));

      accumulator = [...accumulator, ...configsForCurrentResolution];
    }

    return accumulator;
  }, []);
}
