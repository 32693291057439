import { RawConfigsLivetesting } from '../../../types';
import { ConfigsLivetestingActionTypes } from './types';

export function configsLivetestingReducer(
  state: RawConfigsLivetesting | {} = {},
  action: ConfigsLivetestingActionTypes
): RawConfigsLivetesting | {} {
  switch (action.type) {
    case 'FILL_LIVETESTING_CONFIGS':
      const newState: RawConfigsLivetesting = JSON.parse(JSON.stringify(action.payload));
      return newState;

    default:
      return state;
  }
}
