import { ResolutionsState, ResolutionsActionsTypes } from './types';
import { ResolutionsType } from '../../../types';
import { resolutions as defaultResolutions } from './../../../settings.json';

function resolutionsCreator(resolution) {
  return { value: resolution, checked: false };
}

export const resolutions: ResolutionsType[] = defaultResolutions.map(resolutionsCreator);

const initialState: ResolutionsState = {
  resolutions,
  submittedResolutions: [],
  filter: false,
  filteredScreens: [],
};

export function resolutionsReducer(
  state = initialState,
  action: ResolutionsActionsTypes
): ResolutionsState {
  switch (action.type) {
    case 'ADD_RESOLUTION':
      const newResolution = { value: action.payload, checked: false };

      return { ...state, resolutions: [...state.resolutions, newResolution] };

    case 'DELETE_RESOLUTION': {
      const resolutions = state.resolutions.filter(({ value }) => value !== action.payload);

      return { ...state, resolutions };
    }

    case 'CHECK_RESOLUTION': {
      const resolutions = [...state.resolutions];
      const index = resolutions.findIndex(({ value }) => value === action.payload);
      resolutions[index].checked = true;

      return { ...state, resolutions };
    }

    case 'UNCHECK_RESOLUTION': {
      const resolutions = [...state.resolutions];
      const index = resolutions.findIndex(({ value }) => value === action.payload);
      resolutions[index].checked = false;

      return { ...state, resolutions };
    }

    case 'CHECK_RESOLUTIONS': {
      const resolutions = [...state.resolutions];
      action.payload.forEach((resolution) => {
        const index = resolutions.findIndex(({ value }) => value === resolution);
        resolutions[index].checked = true;
      });

      return { ...state, resolutions };
    }

    case 'ADD_SUBMITTED_RESOLUTIONS':
      const submittedResolutions = action.payload.sort((x, y) => x - y);

      return { ...state, submittedResolutions };

    case 'SET_FILTER':
      const filter = action.payload === 'all' ? false : action.payload;

      return { ...state, filter };

    case 'FILL_FILTERED_SCREENS':
      return { ...state, filteredScreens: [...action.payload] };

    case 'CHECK_OR_UNCHECK_ALL_RESOLUTIONS': {
      const isEveryChecked = state.resolutions.every(({ checked }) => checked);
      const resolutions = isEveryChecked
        ? state.resolutions.map(({ value }) => ({ value, checked: false }))
        : state.resolutions.map(({ value }) => ({ value, checked: true }));

      return { ...state, resolutions };
    }

    default:
      return state;
  }
}
