import { ScreensActionsTypes } from './types';
import { ScreenConfig } from '../commonTypes';
import { isScreenAvailable } from '../../../helpers';

const initialState: ScreenConfig[] = [];

export function screensReducer(state = initialState, action: ScreensActionsTypes): ScreenConfig[] {
  const newState = [...state];

  switch (action.type) {
    case 'FILL_SCREENS':
      return [...action.payload];

    case 'ADD_SCREENS':
      return [...state, ...action.payload];

    case 'SET_SCREEN':
      const indexForSetting = newState.findIndex(
        (item) => item.screenId === action.payload.screenId
      );

      if (indexForSetting !== -1) {
        // work around for bug when screen is updated with ooops
        if (isScreenAvailable(newState[indexForSetting].screen) === 'available') return newState;
        // we need to set check state since we do not get this state form the server
        const isChecked = newState[indexForSetting].checked;

        newState[indexForSetting] = { ...action.payload, checked: isChecked };
      }

      return newState;

    case 'TOGGLE_CHECK_SCREEN':
      const indexForChecking = newState.findIndex(
        (item) => item.screenId === action.payload.screenId
      );

      newState[indexForChecking].checked = action.payload.checked;

      return newState;

    case 'CHECK_ALL_SCREENS':
      if (action.payload)
        return state.map((item) => ({ ...item, checked: item.resolution === action.payload }));

      return state.map((item) => ({ ...item, checked: true }));

    case 'UNCHECK_ALL_SCREENS':
      return state.map((item) => ({ ...item, checked: false }));

    case 'DELETE_SCREEN':
      const indexForDeletion = newState.findIndex((item) => item.screenId === action.payload);

      newState.splice(indexForDeletion, 1);

      return newState;

    case 'UPDATE_SCREEN':
      const indexForUpdate = newState.findIndex((item) => item.screenId === action.payload);

      newState[indexForUpdate] = { ...newState[indexForUpdate], screen: undefined };

      return newState;

    case 'UPDATE_SCREENS':
      const screensToUpdate = new Set();

      action.payload.forEach((item) => screensToUpdate.add(item.screenId)); // add IDs of all screens, which we need to update, to our set

      newState.forEach((item, index) => {
        if (screensToUpdate.has(item.screenId)) newState[index] = { ...item, screen: undefined };
      });

      return newState;

    case 'DELETE_CHECKED_SCREENS':
      return [...action.payload]; // in order to delete all checked screens we just remain all unchecked screens

    case 'UPDATE_CHECKED_SCREENS':
      const checked = action.payload.checked.map((item) => ({ ...item, screen: undefined }));

      return [...checked, ...action.payload.unchecked];

    default:
      return state;
  }
}
