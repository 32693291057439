// core
import React from 'react';
// instruments
import useTranslations from '../../hooks/useTranslations';
// styles
import './footer.scss';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__content">
          <div className="footer__text">
            <p className="footer__text-item">
              Copyright &copy; {new Date().getFullYear()} Electronic Team, Inc.
            </p>
            <p className="footer__text-item">
              and its affiliates and licensors.{' '}
              <a href="https://www.electronic.us/legal/" className="footer__text-item-dark">
                Legal Information.
              </a>
            </p>
          </div>
          <nav className="footer__nav">
            <ul className="footer__nav-list">
              <li className="footer__nav-item">
                <a className="footer__nav-link" href="https://comparium.app/privacy-policy.html">
                  Privacy Policy
                </a>
              </li>
              <li className="footer__nav-item">
                <a className="footer__nav-link" href="https://comparium.app/terms-of-service.html">
                  Terms of Service
                </a>
              </li>
              <li className="footer__nav-item">
                <a className="footer__nav-link" href="https://comparium.app/changelog.html">
                  Changelog
                </a>
              </li>
              <li className="footer__nav-item">
                <a className="footer__nav-link" href="https://comparium.app/about.html">
                  About us
                </a>
              </li>
              <li className="footer__nav-item">
                <a className="footer__nav-link" href="https://comparium.app/contacts.html">
                  Contact us
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
}

export default React.memo(Footer);
