// core
import axios, { AxiosResponse } from 'axios';
// TS types
import { RawConfigsCheckboxes, RawConfigsLivetesting } from '../types';

export const api = {
  uploadMockupToCompare(formData: FormData, cancel: { cancelFunction: Function }) {
    return axios.post('/upload/', formData, {
      // create 'cancelToken' in order to get a cancel function to be able to cancel the request
      cancelToken: new axios.CancelToken(function (c) {
        cancel.cancelFunction = c;
      }),
    });
  },
  // check if limit of parallel sessions is reached (if return value is 'true', that means that limit of parallel sessions was not reached)
  checkIfParallelSessionsLimitReached(userId, licenseProduct) {
    return axios.get(`/get/isSessionAllow?userId=${userId}&licenseProduct=${licenseProduct}`);
  },
  // get ID of screenshots session - we need it to send request to the server to close this session (parallel session) in case the user closed browser
  getScreensSessionId() {
    return axios.get(`/get/getSessionId`);
  },
  getRawConfigsCheckboxes(): Promise<AxiosResponse<RawConfigsCheckboxes>> {
    return axios.get('/configs/screenConfigs.json');
  },
  getRawConfigsLivetesting(): Promise<AxiosResponse<RawConfigsLivetesting>> {
    return axios.get('/configs/livetestingConfigs.json');
  },
};
