import { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { LocaleContext } from '../components/layout';

export default function useTranslations() {
  // Grab the locale (passed through context) from the Context Provider
  const { locale } = useContext(LocaleContext);

  // Query the JSON files in the foldr "translations"
  const { rawData } = useStaticQuery(query);

  // Simplify the response from GraphQL
  const simplified = rawData.edges.map(item => {
    return {
      name: item.node.name,
      translations: item.node.translations,
    };
  });

  // Only return translations for the current locale
  const { translations } = simplified.filter(lang => lang.name === locale)[0];

  return translations;
}

const query = graphql`
  query {
    rawData: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            newConfiguration
            width
            testingURL
            placedURL
            addScreens
            createScreens
            viewscreen
            Delete
            update
            pleaseEnterYourURL
            pleaseEnterAValidURL
            pleaseCheckAnyConfiguration
            about
            contact
            oops
            areYouSureYouWantToDeleteConfiguration
            noAvailableVersions
            pleaseChooseAtLeastOneWidth
            thisWidthAlreadyExists
            digitsRange
            screenWidths
            refreshScreenshot
            deleteScreenshot
            addNewConfiguration
            filterWidth
            changeTestedURL
            addCustomWidth
            previous
            next
          }
        }
      }
    }
  }
`;
