import io from 'socket.io-client';

let _socket: SocketIOClient.Socket;

switch (process.env.NODE_ENV) {
  case 'development':
    //_socket = io('https://ws.comparium.app');
    _socket = io('https://ws.test.comparium.app');
    //_socket = io('http://45.56.68.126:3000');
    //_socket = io('https://test.comparium.app:3000', { transports: ['websocket'] });

    _socket.on('connect', () => {
      console.log('Websocket connected');
    });

    _socket.on('connect_error', (connect_error) => {
      console.log('Websocket connection error');
      console.log(connect_error);
    });

    _socket.on('connect_timeout', (connect_timeout) => {
      console.log('Websocket connection timeout');
      console.log(connect_timeout);
    });

    _socket.on('disconnect', (reason) => {
      console.log(`Websocket disconnected!`);
      console.log('Disconnection reason: ', reason);
    });

    break;

  case 'production':
    _socket = io('/', { path: '/ws/socket.io', transports: ['websocket'] });

    _socket.on('connect', () => {
      console.log('Websocket connected');
    });

    _socket.on('connect_error', (connect_error) => {
      console.log('Websocket connect_error');
      console.log(connect_error);
    });

    _socket.on('connect_timeout', (connect_timeout) => {
      console.log('Websocket connect_timeout');
      console.log(connect_timeout);
    });

    _socket.on('disconnect', (reason) => {
      console.log(`Websocket disconnected!`);
      console.log(reason);
    });

    break;
}

export { _socket };
