import chrome from './chrome.svg';
import firefox from './firefox.svg';
import edge from './edge.svg';
import safari from './safari.svg';
import chromeDisabled from './chrome-disabled.svg';
import firefoxDisabled from './firefox-disabled.svg';
import safariDisabled from './safari-disabled.svg';
import chromeNotActive from './chrome-not-active.svg';
import firefoxNotActive from './firefox-not-active.svg';
import safariNotActive from './safari-not-active.svg';

export default {
  chrome,
  firefox,
  edge,
  safari,
  chromeDisabled,
  firefoxDisabled,
  safariDisabled,
  chromeNotActive,
  firefoxNotActive,
  safariNotActive,
};
