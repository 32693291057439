// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-livetesting-js": () => import("./../../../src/pages/livetesting.js" /* webpackChunkName: "component---src-pages-livetesting-js" */),
  "component---src-pages-redemption-login-js": () => import("./../../../src/pages/redemption/login.js" /* webpackChunkName: "component---src-pages-redemption-login-js" */),
  "component---src-pages-request-trial-js": () => import("./../../../src/pages/request/trial.js" /* webpackChunkName: "component---src-pages-request-trial-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

