// core
import React, { useState, useEffect, useContext } from 'react';
import { navigate } from '@reach/router';
import createAuth0Client from '@auth0/auth0-spa-js';
// helpers
import { dataLayerPush, getCookie } from './index';
//default settings
import {
  signupTrialTitle,
  signupTrialTerms,
  paddleVendorId as vendor,
  loginRedemptionTitle,
  namespace,
} from '../settings.json';

export const Auth0Context = React.createContext();

export function useAuth0() {
  return useContext(Auth0Context);
}

export function Auth0Provider({ children, ...initOptions }) {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0Client] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [isTrial, setIsTrial] = useState();
  const [isTrialPopupActive, setIsTrialPopupActive] = useState(false);
  const [isAppSumoAlertActive, setIsAppSumoAlertActive] = useState(false);
  const [action, setAction] = useState();

  useEffect(() => {
    async function initAuth0() {
      const auth0FromHook = await createAuth0Client(initOptions);

      setAuth0Client(auth0FromHook);

      if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();

        if (appState.pathname === '/')
          window.history.replaceState({}, document.title, appState.targetUrl);
        else navigate(appState.pathname);

        if (appState.trial) {
          setIsTrial(true);
          setIsTrialPopupActive(true);
          setPaddleTrialForGA(Paddle);
        } else {
          setIsTrial(false);
          setPaddleSubscriptionForGA(Paddle);
        }

        if (appState.redemption) {
          setIsAppSumoAlertActive(true);
          setTimeout(() => setIsAppSumoAlertActive(false), 10000);
        }

        if (appState.action) {
          setAction(appState.action);
        }

        const claims = await auth0FromHook.getIdTokenClaims();
        const loginsCount = claims?.[`${namespace}/logins_count`];

        if (loginsCount === 1) {
          const dataLayerParams = {
            event: 'customEvent',
            eventCategory: 'info',
            eventAction: 'Registration',
            eventLabel: 'Auth0',
            eventValue: 1,
          };

          dataLayerPush(dataLayerParams);

          let actionField = { affiliation: 'Electronic Team' };
          const offer_id = getCookie('aff_offer_id');

          if (offer_id) {
            actionField.offer_id = offer_id;
          }

          const dataLayerParams2 = {
            event: 'registration',
            registration: { actionField },
          };

          dataLayerPush(dataLayerParams2);
        }
      } else {
        setIsTrial(false);
        setPaddleSubscriptionForGA(Paddle);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();

        setUser(user);
      }

      setLoading(false);
    }

    initAuth0();
  }, []);

  async function loginWithPopup(params = {}) {
    setPopupOpen(true);

    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }

    const user = await auth0Client.getUser();

    setUser(user);
    setIsAuthenticated(true);
  }

  async function handleRedirectCallback() {
    setLoading(true);
    await auth0Client.handleRedirectCallback();

    const user = await auth0Client.getUser();

    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  }

  async function loginWithRedirect(params) {
    const {
      targetUrl,
      pathname,
      login_hint,
      prevent_sign_up,
      screen_hint,
      trial,
      redemption,
      action,
    } = params;

    let redirectParams = {
      appState: { targetUrl, pathname },
      redirect_uri: window.location.origin,
    };

    if (login_hint) redirectParams = { ...redirectParams, login_hint };

    if (screen_hint) redirectParams = { ...redirectParams, screen_hint };

    if (prevent_sign_up) redirectParams = { ...redirectParams, prevent_sign_up };

    if (trial) {
      const trial = JSON.stringify({
        signup: { title: signupTrialTitle, terms: signupTrialTerms },
      });
      const appState = { ...redirectParams.appState, trial: true };
      redirectParams = { ...redirectParams, appState, trial };
    }

    if (action) {
      const appState = { ...redirectParams.appState, action };
      redirectParams = { ...redirectParams, appState, screen_hint: 'signUp' };
    }

    if (redemption) {
      const redemption = JSON.stringify({ title: loginRedemptionTitle });
      const appState = { ...redirectParams.appState, redemption: true };
      redirectParams = { ...redirectParams, appState, redemption };
    }

    await auth0Client.loginWithRedirect(redirectParams);
  }

  async function getUser() {
    const auth0 = await createAuth0Client(initOptions);
    return auth0.getUser();
  }

  return (
    <Auth0Context.Provider
      value={{
        isAppSumoAlertActive,
        action,
        isTrialPopupActive,
        setIsTrialPopupActive,
        isTrial,
        setIsTrial,
        auth0Client,
        isAuthenticated,
        user,
        setUser,
        getUser,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect,
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
}

function setPaddleTrialForGA(Paddle) {
  Paddle.Setup({ vendor, eventCallback });

  function eventCallback(data) {
    const eventValue = checkError(data);
    const dataLayerParams = {
      event: 'customEvent',
      eventCategory: 'info',
      eventAction: 'request trial',
      eventLabel: data?.event,
      eventValue: eventValue,
    };

    dataLayerPush(dataLayerParams);
  }
}

function setPaddleSubscriptionForGA(Paddle) {
  Paddle.Setup({ vendor, eventCallback });

  function eventCallback(data) {
    const eventValue = checkError(data);
    const dataLayerParams = {
      event: 'customEvent',
      eventCategory: 'info',
      eventAction: 'checkout',
      eventLabel: data?.event,
      eventValue: eventValue,
    };

    dataLayerPush(dataLayerParams);
  }
}

function checkError(data) {
  if (data?.event === 'Checkout.Error') return data?.eventData?.error;
  else return 1;
}
