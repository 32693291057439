// core
import React from 'react';
import App from './app';
import { Provider } from 'react-redux';
// redux store
import { store } from '../store/store';

const LocaleContext = React.createContext();

// Use the built-in Context API to make the "locale" available to every component in the
// tree. As this component wraps every page (due to the wrapPageElement API) we can be
// sure to have the locale available everywhere!
function Layout({ pageContext: { locale }, path, children }) {
  return (
    <LocaleContext.Provider value={{ locale, path }}>
      <Provider store={store}>
        <App>{children}</App>
      </Provider>
    </LocaleContext.Provider>
  );
}

export { Layout, LocaleContext };
