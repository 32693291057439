// core
import React, { useState, useRef, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate, Link } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';
import ProgressBar from '@material-ui/core/LinearProgress';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
// instruments
import { LocaleContext } from '../layout';
//import useTranslations from '../../hooks/useTranslations';
import { upperCaseFirstLetter } from '../../helpers';
import { useAuth0 } from '../../helpers/auth';
//default settings
import { app_metadata_namespace } from '../../settings.json';
// images
import IconsLocal from './images';
import Icons from '../../images';
// styles
import './header.scss';
import progressBarClasses from './progressBarStyles';

function Header() {
  // hooks
  const dispatch = useDispatch();
  const isHeaderCloseButtonActive = useSelector((state) => state.ui.isHeaderCloseButtonActive);
  const isConfigMobileWithURLActive = useSelector((state) => state.ui.isConfigMobileWithURLActive);
  const isConfigMobileNoURLActive = useSelector((state) => state.ui.isConfigMobileNoURLActive);
  const userLimitsAndStats = useSelector((state) => state.userLimitsAndStats);
  const userDoesntHavePlan = useSelector((state) => state.ui.userDoesntHavePlan);
  //const selectDesktopRef = useRef(null);
  //const selectMobileRef = useRef(null);
  const [isMenuAuthActive, setIsMenuAuthActive] = useState(false);
  const [isLimitsAndStatsActive, setLimitsAndStatsActive] = useState(false);
  const [emailButtonStyle, setEmailButtonStyle] = useState(null);
  const navRef = useRef(null);
  const statsRef = useRef(null);
  const emailButtonRef = useRef(null);
  const emailLabelRef = useRef(null);
  const { user, logout } = useAuth0();
  const { path } = useContext(LocaleContext);
  const isDesktopMin1280 = useMediaQuery({ minWidth: 1281 });
  const isDesktop1024_1279 = useMediaQuery({ minWidth: 1024, maxWidth: 1279 });
  const isDesktop769_1023 = useMediaQuery({ minWidth: 769, maxWidth: 1023 });
  const isDesktop = useMediaQuery({ minWidth: 769 }); // if screen width is more than 768px
  const isSmallDesktop = useMediaQuery({ maxWidth: 1023 }); // if screen width is less than or equal to 1024px
  const isMobile = useMediaQuery({ maxWidth: 768 }); // if screen width is less than or equal to 768px

  useEffect(() => {
    function hide(event) {
      if (isMenuAuthActive && !navRef.current.contains(event.target)) {
        setIsMenuAuthActive(false);
      }
    }

    document.addEventListener('click', hide);

    return () => document.removeEventListener('click', hide);
  }, [isMenuAuthActive]);

  useEffect(() => {
    function hide(event) {
      if (isLimitsAndStatsActive && !statsRef.current.contains(event.target)) {
        setLimitsAndStatsActive(false);
      }
    }

    document.addEventListener('click', hide);

    return () => document.removeEventListener('click', hide);
  }, [isLimitsAndStatsActive]);

  // destructuring user limits and stats data
  const {
    license_subscription_vnc_count: vncLimit,
    count_vnc_for_period: vncCount,
    license_subscription_screens_count: screensLimit,
    count_screens_for_period: screensCount,
    license_session_parallels_count: parallelsLimit,
    count_parallel_tests_for_now: parallelsCount,
    license_short_name,
    areScreensUnlimited,
    areLiveSessionsUnlimited,
    isScreensLimitReached,
    isLiveSessionsLimitReached,
  } = userLimitsAndStats;

  // check if screens and live testing limits are reached,
  // and if all stats variables are not equal to null
  let isLimitReached;

  switch (path) {
    case '/':
      isLimitReached = isScreensLimitReached && !areScreensUnlimited;
      break;

    case '/livetesting':
      isLimitReached = isLiveSessionsLimitReached && !areLiveSessionsUnlimited;
      break;
  }

  // compute CSS classes
  const menuClasses = cx('header__nav', { active: isMenuAuthActive });
  const authBtnClasses = cx('header__authenticated-btn', { active: isMenuAuthActive });
  const closeButtonClasses = cx('header__close-btn', {
    active: isHeaderCloseButtonActive,
  });
  const limitsAndStatsBtnClasses = cx('header__limits-and-stats-btn', {
    active: isLimitsAndStatsActive,
    limitReached: isLimitReached,
  });
  const limitsAndStatsPanelClasses = cx('header__limits-and-stats-panel', {
    active: isLimitsAndStatsActive,
  });

  // compute logo label
  const logoLabel = path === '/account' ? null : 'Comparium';

  // check if this one of the main pages (Screenshots, Live testing)
  const isMainPage = path === '/' || path === '/livetesting';

  // check if "limits and stats" button has to be disabled (it doesn't have to be disabled on mobile devices)
  const isButtonDisabled = isDesktop && areScreensUnlimited && areLiveSessionsUnlimited;

  // label of 'limits and stats' drop-down
  let limitsAndStatsDropDownLabel;

  if (areScreensUnlimited && areLiveSessionsUnlimited) {
    const planName = upperCaseFirstLetter(license_short_name?.toLowerCase());
    limitsAndStatsDropDownLabel = `${planName} plan limits`;
  } else {
    const planName = userDoesntHavePlan
      ? license_short_name?.toUpperCase()
      : upperCaseFirstLetter(license_short_name?.toLowerCase());
    limitsAndStatsDropDownLabel = `${planName} plan. Monthly limits`;
  }

  // find out icons, text and progress bar color
  const screensIconColor = defineIconColor(isScreensLimitReached);
  const vncIconColor = defineIconColor(isLiveSessionsLimitReached);
  const screensTextColor = defineTextColor(isScreensLimitReached);
  const vncTextColor = defineTextColor(isLiveSessionsLimitReached);
  const screensProgressbarColor = defineProgressbarColor(isScreensLimitReached);
  const vncProgressbarColor = defineProgressbarColor(isLiveSessionsLimitReached);

  // find out progress of screens, live testing and parallel sessions limits
  const screensProgress = findOutProgress(screensCount, screensLimit);
  const vncProgress = findOutProgress(vncCount, vncLimit);
  const parallelsProgress = findOutProgress(parallelsCount, parallelsLimit);

  function computeStatsAndLimitsTitle() {
    if (isSmallDesktop) return null;

    if (areScreensUnlimited && areLiveSessionsUnlimited) {
      return `${parallelsCount}/${parallelsLimit} parallel tests`;
    }

    switch (path) {
      case '/':
        return `${screensCount}/${screensLimit} screenshots`;

      case '/livetesting':
        return `${vncCount}/${vncLimit} live sessions`;
    }
  }

  function toggleMenuAuth() {
    setIsMenuAuthActive((prevState) => !prevState);
  }

  function hideConfigMobile() {
    if (isConfigMobileWithURLActive) {
      dispatch(uiActions.hideConfigMobileWithURL());
      dispatch(uiActions.hideTabsMobileWithURL());
    }

    if (isConfigMobileNoURLActive) {
      dispatch(uiActions.hideConfigMobileNoURL());
    }

    dispatch(uiActions.hideHeaderCloseButton());
  }

  function toggleLimitsAndStats() {
    setLimitsAndStatsActive((prevState) => !prevState);
  }

  function navigateToPricingTab(event) {
    event.preventDefault();
    if (isMenuAuthActive) setIsMenuAuthActive(false);
    if (isLimitsAndStatsActive) setLimitsAndStatsActive(false);
    dispatch(uiActions.toggleAccountPageTabsValue(1));
    navigate('/account');
  }

  const email = user?.email;

  useEffect(() => {
    let emailTrimmed, newEmailButtonNodeLength;
    const emailLength = email?.length;

    // we need to find out if we need to cut email name
    if (isDesktopMin1280 && emailLength > 34) {
      emailTrimmed = email?.trim().slice(0, 34);
    } else if (isDesktop1024_1279 && emailLength > 16) {
      emailTrimmed = email?.trim().slice(0, 19);
    } else if (isDesktop769_1023 && emailLength > 9) {
      emailTrimmed = email?.trim().slice(0, 12);
    }

    // if email name was trimmed we define the width of email button depending on the length of trimmed email
    if (emailTrimmed) {
      const emailNode = document.createElement('span');
      emailNode.textContent = emailTrimmed;
      emailNode.style.fontSize = 16;
      emailNode.style.position = 'absolute';
      emailNode.style.left = -1000;
      emailNode.style.top = -1000;

      document.body.appendChild(emailNode);

      const emailLabelNodeTrimmedLength = emailNode.offsetWidth;
      const currentEmailLabelNodeLength = emailLabelRef.current?.offsetWidth;
      const currentEmailButtonNodeLength = emailButtonRef.current?.offsetWidth;
      const additionalLength = currentEmailButtonNodeLength - currentEmailLabelNodeLength; // length we need to add to trimmed "email label" in order to make correct button length
      newEmailButtonNodeLength = emailLabelNodeTrimmedLength + additionalLength;

      document.body.removeChild(emailNode);
    }

    // if it is mobile, the length of the email button must be 52px, if it is not mobile
    const emailButtonStyle = isMobile
      ? { width: 52 }
      : emailTrimmed
      ? { width: newEmailButtonNodeLength || 0, display: newEmailButtonNodeLength ? '' : 'none' } // if newEmailButtonNodeLength is NaN, we set width value to 0
      : null;

    setEmailButtonStyle(emailButtonStyle);
  }, []);

  /*function changeLocale(selectRef) {
    navigate(`${selectRef.current.value}${window.location.search}`);
  }*/

  return (
    <header className="header">
      <div className="container">
        <div className="header__content">
          {/* block hidden-links is work around, because for some reason, in Gatsby, 
      programmatic navigation with function navigate() causes page reload, 
      if there is no links with the same paths in the viewport.
       */}
          {/* <div className="header__hidden-links">
            <Link to="/de">DE</Link>
            <Link to="/es">ES</Link>
            <Link to="/cn">中文</Link>
          </div>
          */}

          <button className={closeButtonClasses} onClick={hideConfigMobile}>
            <Icons.cross />
          </button>
          <a href="/" className="header__logo">
            <IconsLocal.logo />
            {logoLabel}
          </a>
          {!logoLabel && (
            <Link className="header__back-to-service-btn" to="/">
              BACK TO SERVICE
            </Link>
          )}
          {isDesktop && isMainPage && (
            <div className="header__pageSelector">
              <Link className="header__pageSelector-tab" to="/">
                Screenshots
              </Link>
              <Link className="header__pageSelector-tab" to="/livetesting">
                Live testing
              </Link>
            </div>
          )}

          {/*<select
            className="header__select desktop"
            name="locales"
            ref={selectDesktopRef}
            onChange={() => changeLocale(selectDesktopRef)}
          >
            <option value="/">English</option>
            <option value="/de">Deutsch</option>
            <option value="/es">Español</option>
            <option value="/cn">中文</option>
          </select>
          <select
            className="header__select mobile"
            name="locales"
            ref={selectMobileRef}
            onChange={() => changeLocale(selectMobileRef)}
          >
            <option value="/">EN</option>
            <option value="/de">DE</option>
            <option value="/es">ES</option>
            <option value="/cn">中文</option>
          </select>*/}

          {isMainPage && (
            <div className="header__limits-and-stats">
              {isMobile && isMenuAuthActive ? null : (
                <button
                  onClick={toggleLimitsAndStats}
                  className={limitsAndStatsBtnClasses}
                  disabled={isButtonDisabled}
                >
                  {isMobile && isLimitsAndStatsActive ? null : isLimitReached ? (
                    <IconsLocal.statsWarning />
                  ) : (
                    <IconsLocal.stats />
                  )}
                  {computeStatsAndLimitsTitle()}
                  {isMobile && isLimitsAndStatsActive && (
                    <span className="header__btn-mobile-line"></span>
                  )}
                </button>
              )}
              <div className={limitsAndStatsPanelClasses} ref={statsRef}>
                <p className="header__limits-and-stats-panel-heading">
                  {limitsAndStatsDropDownLabel}
                </p>
                <ul className="header__limits-and-stats-panel-list">
                  {/* we mustn't render this if screensLimit = 0 - this means that it is unlimitted */}
                  {!areScreensUnlimited && (
                    <li className="header__limits-and-stats-panel-list-item">
                      <div className="header__limits-and-stats-panel-list-item-wrapper">
                        <IconsLocal.camera color={screensIconColor} />
                        <p
                          style={{ color: screensTextColor }}
                          className="header__limits-and-stats-panel-list-item-text"
                        >
                          {screensCount}/{screensLimit} screenshots
                        </p>
                      </div>
                      <ProgressBar
                        classes={progressBarClasses}
                        variant="determinate"
                        value={screensProgress}
                        color={screensProgressbarColor}
                      />
                    </li>
                  )}
                  {/* we mustn't render this if vncLimit = 0 - this means that it is unlimitted */}
                  {!areLiveSessionsUnlimited && (
                    <li className="header__limits-and-stats-panel-list-item">
                      <div className="header__limits-and-stats-panel-list-item-wrapper">
                        <IconsLocal.cursor color={vncIconColor} />
                        <p
                          style={{ color: vncTextColor }}
                          className="header__limits-and-stats-panel-list-item-text"
                        >
                          {vncCount}/{vncLimit} live testing sessions
                        </p>
                      </div>
                      <ProgressBar
                        classes={progressBarClasses}
                        variant="determinate"
                        value={vncProgress}
                        color={vncProgressbarColor}
                      />
                    </li>
                  )}
                  <li className="header__limits-and-stats-panel-list-item">
                    <div className="header__limits-and-stats-panel-list-item-wrapper">
                      <IconsLocal.parallel />
                      <p className="header__limits-and-stats-panel-list-item-text">
                        {parallelsCount}/{parallelsLimit} parallel test
                      </p>
                    </div>
                    <ProgressBar
                      classes={progressBarClasses}
                      variant="determinate"
                      value={parallelsProgress}
                      color={'primary'}
                    />
                  </li>
                </ul>
                {isMobile && userDoesntHavePlan && (
                  <button
                    className="header__limits-and-stats-upgrade-btn orange-gradient-btn two-border-active"
                    onClick={navigateToPricingTab}
                  >
                    Upgrade
                  </button>
                )}
              </div>
            </div>
          )}
          <div className="header__authenticated">
            {isMainPage && isDesktop && userDoesntHavePlan && (
              <button
                className="header__upgrade-btn orange-gradient-btn two-border-active"
                onClick={navigateToPricingTab}
              >
                Upgrade
              </button>
            )}
            <nav className={menuClasses} ref={navRef}>
              <ul className="header__nav-list">
                <li className="header__nav-item">
                  <span className="header__nav-item-label">{email}</span>
                  <Link
                    className="header__nav-link"
                    to="/account"
                    onClick={() => setIsMenuAuthActive(false)}
                  >
                    <IconsLocal.billing /> Billing info
                  </Link>
                </li>
                <li className="header__nav-item">
                  <a
                    className="header__nav-link"
                    href="https://howto.comparium.app/"
                    target="_blank"
                  >
                    <IconsLocal.userGuide />
                    User Guide
                  </a>
                </li>
                {userDoesntHavePlan && (
                  <li className="header__nav-item">
                    <a className="header__nav-link" href="#" onClick={navigateToPricingTab}>
                      <IconsLocal.upgrage /> Upgrade
                    </a>
                  </li>
                )}
                <li className="header__nav-item">
                  <a
                    className="header__nav-link"
                    href="#"
                    onClick={() => logout({ returnTo: window.location.origin })}
                  >
                    <IconsLocal.logout />
                    Log out
                  </a>
                </li>
              </ul>
            </nav>
            {isMobile && isLimitsAndStatsActive ? null : (
              <button
                onClick={toggleMenuAuth}
                className={authBtnClasses}
                style={emailButtonStyle}
                ref={emailButtonRef}
              >
                {isDesktop ? <IconsLocal.user /> : !isMenuAuthActive && <IconsLocal.user />}
                {isDesktop && (
                  <span className="header__authenticated-btn-label" ref={emailLabelRef}>
                    {email}
                  </span>
                )}
                {isMobile && <span className="header__btn-mobile-line"></span>}
              </button>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

// function to find out progress of Progress bar
function findOutProgress(count, limit) {
  return count <= limit ? (count / limit) * 100 : 100; // in case if accidentally the value of count bigger than the value of limit we always return 100
}

// function to find out icons color
function defineIconColor(condition) {
  return condition ? '#FF763B' : '#606772';
}

// function to find out text color
function defineTextColor(condition) {
  return condition ? '#FF763B' : '#9DA2AA';
}

// function to find out progress bar color
function defineProgressbarColor(condition) {
  return condition ? 'secondary' : 'primary';
}

export default React.memo(Header);
