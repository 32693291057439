import React from 'react';
import { Layout } from './src/components/layout';
//import * as ourLocales from './src/data/locales.js';
import { Auth0Provider } from './src/helpers/auth';
import { auth0_domain, auth0_clientid } from './src/settings.json';

// get all locales
/*const locales = Object.keys(ourLocales).map(item =>
  item === 'en' ? '/' : `/${item}`
);*/

// Pass all props to the Layout component so it has access to
// things like pageContext or location
function wrapPageElement({ element, props }) {
  /*if (
    locales.includes(props.path) ||
    (props.path && props.path.includes('/account'))
  ) */

  return (
    <Auth0Provider domain={auth0_domain} client_id={auth0_clientid}>
      <Layout {...props}>{element}</Layout>
    </Auth0Provider>
  );
}

export default wrapPageElement;
