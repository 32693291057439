// core
import React, { useEffect, useRef, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
import { loginProfilesActions } from '../../store/domains/loginProfiles/actions';
//socket
import { _socket as socket } from '../../socket';
// styles
import './loginProfileDialog.scss';
// typescript types
import { RootState } from '../../../../store/rootReducer';

function LoginProfileDialog(): ReactElement {
  // hooks
  const dispatch = useDispatch();
  const dialogRef = useRef(null);
  const isDialogActive = useSelector((state: RootState) => state.ui.isLoginProfileDialogActive);
  const id = useSelector((state: RootState) => state.loginProfiles.profileForDeletion);

  useEffect(() => {
    function hidePanel(event: MouseEvent): void {
      if (isDialogActive && !dialogRef.current.contains(event.target))
        dispatch(uiActions.toggleLoginProfileDialog(false));
    }

    document.addEventListener('click', hidePanel);

    return () => document.removeEventListener('click', hidePanel);
  }, [isDialogActive]);

  function yes() {
    dispatch(loginProfilesActions.deleteProfile(id));
    dispatch(uiActions.toggleLoginProfileDialog(false));

    socket.emit('deleteLoginProfile', { id });
  }

  function no() {
    dispatch(uiActions.toggleLoginProfileDialog(false));
  }

  return (
    <div className="login-profile-dialog popup-overlay">
      <div className="login-profile-dialog__window popup" ref={dialogRef}>
        <p className="login-profile-dialog__text dialog__text">
          Are you sure you want to delete the saved login profile?
        </p>
        <div className="dialog__buttons">
          <button className="dialog__button" onClick={no}>
            NO
          </button>
          <button className="dialog__button" onClick={yes}>
            YES
          </button>
        </div>
      </div>
    </div>
  );
}

export default React.memo(LoginProfileDialog);
