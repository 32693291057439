// core
import React, { useEffect, useRef, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
//socket
import { _socket as socket } from '../../socket';
// instruments
import { generateUniqueId, generateHashForVncSession } from '../../helpers';
import { useAuth0 } from '../../helpers/auth';
//default settings
import { app_metadata_namespace } from '../../settings.json';
// images
import Icons from '../../images';
// styles
import './realTimeTestingPopup.scss';
// typescript types
import { RootState } from '../../store/rootReducer';

function RealTimeTestingPopup(): ReactElement {
  // hooks
  const dispatch = useDispatch();
  const vncSessionTimeout = useSelector((state: RootState) => state.ui.vncSessionTimeout);
  const popupStatus = useSelector((state: RootState) => state.ui.isRealTimeTestingPopupActive);
  const popupRef = useRef(null);

  // hide popup on click on overlay
  useEffect(() => {
    function hidePopup(event: MouseEvent): void {
      if (popupStatus && popupRef.current === event.target) {
        handleClose();
      }
    }

    document.addEventListener('click', hidePopup);

    return () => document.removeEventListener('click', hidePopup);
  }, [popupStatus]);

  const popupHeading = getPopupHeading(popupStatus);
  const popupText = getPopupText(popupStatus, vncSessionTimeout / 60); // convert vncSessionTimeout to minutes

  function getPopupHeading(popupStatus: 'free' | 'paid' | 'error' | false) {
    if (popupStatus === 'free' || popupStatus === 'paid') return 'Session timeout';

    if (popupStatus === 'error') return 'Connection error';
  }

  function getPopupText(popupStatus: 'free' | 'paid' | 'error' | false, vncSessionTimeout: number) {
    switch (popupStatus) {
      case 'paid':
        return `Each session lasts ${vncSessionTimeout} minutes. After the session timeout, you can restart the session again or leave the mode.`;

      case 'free':
        return `Session time in Free Plan is limited to ${vncSessionTimeout} minutes. If you want to increase your testing time, please upgrade your plan.`;

      case 'error':
        return `An error has occurred while trying to establish a connection to a remote desktop. Please try again later.`;
    }
  }

  function navigateToPricingTab() {
    dispatch(uiActions.toggleAccountPageTabsValue(1));
    dispatch(uiActions.toggleRealTimeTestingPopup(false));
    navigate('/account');
  }

  function handleRestartClick() {
    dispatch(uiActions.toggleRealTimeTestingPreloader(true));
    dispatch(uiActions.toggleRealTimeTestingPopup(false));
  }

  function handleClose() {
    dispatch(uiActions.toggleRealTimeTestingPopup(false));
  }

  return (
    <div className="realTimeTestingPopup popup-overlay" ref={popupRef}>
      <div className="realTimeTestingPopup__content popup">
        <h2 className="realTimeTestingPopup__heading">{popupHeading}</h2>
        <p className="realTimeTestingPopup__text">{popupText}</p>
        <div className="realTimeTestingPopup__buttons">
          {popupStatus === 'paid' && (
            <button className="realTimeTestingPopup__btn blue-btn" onClick={handleRestartClick}>
              Restart
            </button>
          )}
          {popupStatus === 'free' && (
            <button
              className="realTimeTestingPopup__btn orange-gradient-btn two-border-active"
              onClick={navigateToPricingTab}
            >
              Upgrade
            </button>
          )}
          {popupStatus === 'error' && (
            <button className="realTimeTestingPopup__btn blue-btn" onClick={handleClose}>
              OK
            </button>
          )}
        </div>
        <button className="realTimeTestingPopup__close-btn square-btn" onClick={handleClose}>
          <Icons.cross />
        </button>
      </div>
    </div>
  );
}

export default React.memo(RealTimeTestingPopup);
