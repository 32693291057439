import { combineReducers } from 'redux';
import { uiReducer as ui } from './domains/ui/reducer';
import { screensReducer as screens } from './domains/screenshots/reducer';
import { resolutionsReducer as resolutions } from './domains/resolutions/reducer';
import { configsCheckboxesWithURLReducer as configsCheckboxesWithURL } from './domains/configsCheckboxesWithURL/reducer';
import { configsCheckboxesNoURLReducer as configsCheckboxesNoURL } from './domains/configsCheckboxesNoURL/reducer';
import { loginProfilesReducer as loginProfiles } from './domains/loginProfiles/reducer';
import { userLimitsAndStatsReducer as userLimitsAndStats } from './domains/userLimitsAndStats/reducer';
import { configsLivetestingReducer as configsLivetesting } from './domains/configsLivetesting/reducer';

export const rootReducer = combineReducers({
  ui,
  screens,
  resolutions,
  configsCheckboxesWithURL,
  configsCheckboxesNoURL,
  loginProfiles,
  userLimitsAndStats,
  configsLivetesting,
});

export type RootState = ReturnType<typeof rootReducer>;
