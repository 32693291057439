import { disableScroll } from '../../../helpers';
import { DemoreminderType } from '../../../types';
import {
  UiActionTypes,
  ChangeURLAction,
  SetScreenNumberAction,
  SetIsURLValidAction,
  SetIsWidthSelectedAction,
  SetScreenConfigForDeletionAction,
  ToggleSelectionStatusAction,
  ToggleDemoreminderAction,
  ToggleUserDoesntHavePlanAction,
  ToggleThanksForPurchasePopupAction,
  ToggleThanksForPurchaseAlertAction,
  TogglePlanActiveAlertAction,
  ToggleCompareAction,
  ToggleCompareMobileAction,
  RealTimeTestingPreloaderAction,
  RealTimeTestingAction,
  SetConfigForRealTimeTestingAction,
  SetVncSessionUrlAction,
  ToggleRealTimeTestingPopupAction,
  SetVncSessionTimeoutAction,
  SetCredentialsForBasicAuthAction,
  ToggleCompareDialogAction,
  ToggleAreCredentialsForBasicAuthConfirmedAction,
  ToggleLoginProfileCreatorPopupAction,
  ToggleLoginProfileCreatorPopupMobileAction,
  ToggleLoginProfileDialogAction,
  TogglePaymentDeatilsUpdatedAlertAction,
  ToggleContinueWithTrialPageActiveAction,
  ToggleAccountPageTabsValueAction,
  SetVncSessionIdAction,
  SetScreensSessionIdAction,
  ToggleSmartScrollSwitchAction,
} from './types';
import { OsBrVerScrid } from '../commonTypes';

export const uiActions = {
  changeURL(url: string): ChangeURLAction {
    return { type: 'CHANGE_URL', payload: url };
  },
  setScreenNumber(number: number): SetScreenNumberAction {
    return { type: 'SET_SCREEN_NUMBER', payload: number };
  },
  setIsURLValid(status: boolean): SetIsURLValidAction {
    return { type: 'VALIDATE_URL', payload: status };
  },
  setIsWidthSelected(status: boolean): SetIsWidthSelectedAction {
    return { type: 'VALIDATE_WIDTH', payload: status };
  },
  setScreenConfigForDeletion(config: OsBrVerScrid): SetScreenConfigForDeletionAction {
    return { type: 'SET_SCREEN_CONFIG_FOR_DELETION', payload: config };
  },
  setConfigForRealTimeTesting(config: object): SetConfigForRealTimeTestingAction {
    return { type: 'SET_CONFIG_FOR_REAL_TIME_TESTING', payload: config };
  },
  showHeaderCloseButton(): UiActionTypes {
    return { type: 'SHOW_HEADER_CLOSE_BUTTON' };
  },
  hideHeaderCloseButton(): UiActionTypes {
    return { type: 'HIDE_HEADER_CLOSE_BUTTON' };
  },
  showDialog(): UiActionTypes {
    return { type: 'SHOW_DIALOG' };
  },
  closeDialog(): UiActionTypes {
    return { type: 'CLOSE_DIALOG' };
  },
  showModal(): UiActionTypes {
    return { type: 'SHOW_MODAL' };
  },
  closeModal(): UiActionTypes {
    return { type: 'CLOSE_MODAL' };
  },
  showConfigMobileNoURL(): UiActionTypes {
    return { type: 'SHOW_CONFIG_MOBILE_NO_URL' };
  },
  hideConfigMobileNoURL(): UiActionTypes {
    return { type: 'HIDE_CONFIG_MOBILE_NO_URL' };
  },
  toggleConfigDesktopNoURL(): UiActionTypes {
    return { type: 'TOGGLE_CONFIG_DESKTOP_NO_URL' };
  },
  hideConfigDesktopNoURL(): UiActionTypes {
    return { type: 'HIDE_CONFIG_DESKTOP_NO_URL' };
  },
  showConfigMobileWithURL(): UiActionTypes {
    return { type: 'SHOW_CONFIG_MOBILE_WITH_URL' };
  },
  hideConfigMobileWithURL(): UiActionTypes {
    return { type: 'HIDE_CONFIG_MOBILE_WITH_URL' };
  },
  showTabsMobileWithURL(): UiActionTypes {
    return { type: 'SHOW_TABS_MOBILE_WITH_URL' };
  },
  hideTabsMobileWithURL(): UiActionTypes {
    return { type: 'HIDE_TABS_MOBILE_WITH_URL' };
  },
  toggleSelectionStatus(status: boolean): ToggleSelectionStatusAction {
    return { type: 'TOGGLE_SELECTION_STATUS', payload: status };
  },
  toggleDemoreminder(status: DemoreminderType): ToggleDemoreminderAction {
    if (status) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = '';

    return { type: 'TOGGLE_DEMOREMINDER', payload: status };
  },
  toggleUserDoesntHavePlan(status: boolean): ToggleUserDoesntHavePlanAction {
    return { type: 'TOGGLE_USER_DOESNT_HAVE_PLAN', payload: status };
  },
  toggleThanksForPurchasePopup(status: boolean): ToggleThanksForPurchasePopupAction {
    return { type: 'TOGGLE_THANKS_FOR_PURCHASE_POPUP', payload: status };
  },
  toggleThanksForPurchaseAlert(status: boolean): ToggleThanksForPurchaseAlertAction {
    return { type: 'TOGGLE_THANKS_FOR_PURCHASE_ALERT', payload: status };
  },
  togglePlanActiveAlert(status: boolean): TogglePlanActiveAlertAction {
    return { type: 'TOGGLE_PLAN_ACTIVE_ALERT', payload: status };
  },
  toggleCompare(status: boolean): ToggleCompareAction {
    disableScroll(status);

    return { type: 'TOGGLE_COMPARE_ACTION', payload: status };
  },
  toggleCompareMobile(status: boolean): ToggleCompareMobileAction {
    disableScroll(status);

    return { type: 'TOGGLE_COMPARE_MOBILE_ACTION', payload: status };
  },
  toggleRealTimeTestingPreloader(status: boolean): RealTimeTestingPreloaderAction {
    return { type: 'TOGGLE_REAL_TIME_TESTING_PRELOADER', payload: status };
  },
  toggleRealTimeTestingPopup(
    status: 'free' | 'paid' | 'error' | false
  ): ToggleRealTimeTestingPopupAction {
    return { type: 'TOGGLE_REAL_TIME_TESTING_POPUP', payload: status };
  },
  toggleRealTimeTesting(status: boolean): RealTimeTestingAction {
    return { type: 'TOGGLE_REAL_TIME_TESTING', payload: status };
  },
  setVncSessionUrl(url: string): SetVncSessionUrlAction {
    return { type: 'SET_VNC_SESSION_URL', payload: url };
  },
  setVncSessionTimeout(time: number): SetVncSessionTimeoutAction {
    return { type: 'SET_VNC_SESSION_TIMEOUT', payload: time };
  },
  setVncSessionId(id: string): SetVncSessionIdAction {
    return { type: 'SET_VNC_SESSION_ID', payload: id };
  },
  toggleCompareDialog(status: boolean): ToggleCompareDialogAction {
    return { type: 'TOGGLE_COMPARE_DIALOG', payload: status };
  },
  setCredentialsForBasicAuth(credentials: {
    user_auth: string;
    user_pass: string;
  }): SetCredentialsForBasicAuthAction {
    return { type: 'SET_CREDENTIALS_FOR_BASIC_AUTH', payload: credentials };
  },
  toggleAreCredentialsForBasicAuthConfirmed(
    status: boolean
  ): ToggleAreCredentialsForBasicAuthConfirmedAction {
    return { type: 'TOGGLE_ARE_CREDENTIALS_FOR_BASIC_AUTH_CONFIRMED', payload: status };
  },
  toggleLoginProfileCreatorPopup(status: boolean): ToggleLoginProfileCreatorPopupAction {
    return { type: 'TOGGLE_LOGIN_PROFILE_CREATOR_POPUP', payload: status };
  },
  toggleLoginProfileCreatorPopupMobile(
    status: boolean
  ): ToggleLoginProfileCreatorPopupMobileAction {
    return { type: 'TOGGLE_LOGIN_PROFILE_CREATOR_POPUP_MOBILE', payload: status };
  },
  toggleLoginProfileDialog(status: boolean): ToggleLoginProfileDialogAction {
    return { type: 'TOGGLE_LOGIN_PROFILE_DIALOG', payload: status };
  },
  toggleContinueWithTrialPageActive(status: boolean): ToggleContinueWithTrialPageActiveAction {
    return { type: 'TOGGLE_CONTINUE_WITH_TRIAL_PAGE_ACTIVE', payload: status };
  },
  togglePaymentDeatilsUpdatedAlert(status: boolean): TogglePaymentDeatilsUpdatedAlertAction {
    return { type: 'TOGGLE_PAYMENT_DETAILS_UPDATED_ALERT', payload: status };
  },
  toggleAccountPageTabsValue(status: number): ToggleAccountPageTabsValueAction {
    return { type: 'TOGGLE_ACCOUNT_PAGE_TABS_VALUE', payload: status };
  },
  setScreensSessionId(id: string): SetScreensSessionIdAction {
    return { type: 'SET_SCREENS_SESSION_ID', payload: id };
  },
  toggleSmartScrollSwitch(status: boolean): ToggleSmartScrollSwitchAction {
    return { type: 'TOGGLE_SMART_SCROLL_SWITCH', payload: status };
  },
};

export type UiActionsType = typeof uiActions;
