export const selectStyles = {
  group: () => ({
    padding: '10px 0',
  }),
  groupHeading: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
    color: '#fff',
    padding: '0 10px',
  }),
  menu: (styles) => ({
    ...styles,
    margin: '6px 0 0',
  }),
  menuList: (styles) => ({
    ...styles,
    background: '#1A1E25',
    borderRadius: 4,
    paddingBottom: 0,
    paddingTop: 0,
    maxHeight: 330,
    minWidth: 270,
  }),
  option: () => ({
    padding: '7px 30px',
    fontSize: 12,
    borderRadius: 4,
    position: 'relative',
    cursor: 'pointer',
    color: '#9DA2AA',
    '&:hover': {
      color: '#fff',
    },
  }),
  container: (styles) => ({
    ...styles,
    width: 270,
    height: 40,
    cursor: 'pointer',
    zIndex: 1,
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  control: (styles) => ({
    ...styles,
    borderColor: '#1A1E25 !important',
    boxShadow: 'none',
    backgroundColor: '#1A1E25',
    borderRadius: 3,
    minHeight: 'auto',
    height: '100%',
    color: '#9DA2AA',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
    },
  }),
  singleValue: (styles) => ({
    ...styles,
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    fontSize: 14,
    maxWidth: 'auto',
    marginLeft: 0,
    marginRight: 0,
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '0 0 0 10px',
    overflow: 'visible',
  }),
};
