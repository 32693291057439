// core
import React, { useState, useEffect, useRef, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
// instruments
import {
  defineOsTitleForVnc,
  createConfigsLivetesting,
  defineBrowserTitle,
  generateUniqueId,
  generateHashForVncSession,
  getCookie,
} from '../../helpers';
import { selectStyles } from './stylesAndSettings';
import { useAuth0 } from '../../helpers/auth';
import { api } from '../../api';
//socket
import { _socket as socket } from '../../socket';
//components
import { selectComponents } from './components/customSelectComponents';
import EnglishLayoutAlert from './components/englishLayoutAlert';
// default settings
import { app_metadata_namespace } from '../../settings.json';
// images
import Icons from '../../images';
import Images from './images';
// styles
import './realTimeTesting.scss';
// typescript types
import { RawConfigsLivetesting } from '../../types';
import { RootState } from '../../store/rootReducer';
type ConfigOption = {
  value: string;
  label: string;
  os: string;
  browser: string;
  version: string;
};
type ConfigOptgroup = { label: string; options: ConfigOption[] };

function RealTimeTesting(): ReactElement {
  // hooks
  const dispatch = useDispatch();
  const vncSessionUrl = useSelector((state: RootState) => state.ui.vncSessionUrl);
  const vncSessionTimeout = useSelector((state: RootState) => state.ui.vncSessionTimeout);
  const vncSessionId = useSelector((state: RootState) => state.ui.vncSessionId);
  const urlForVncSession = useSelector(
    (state: RootState) => state.ui.configForRealTimeTesting.urlForVncSession
  );
  const userDoesntHavePlan = useSelector((state: RootState) => state.ui.userDoesntHavePlan);
  const configForRealTimeTesting = useSelector(
    (state: RootState) => state.ui.configForRealTimeTesting
  );
  const userLimitsAndStats = useSelector((state: RootState) => state.userLimitsAndStats);
  const configsLivetesting = useSelector((state: RootState) => state.configsLivetesting);
  const [isEnglishLayoutAlertActive, setIsEnglishLayoutAlertActive] = useState(false);
  const [configOption, setConfigOption] = useState<ConfigOption>();
  const [configOptions, setConfigOptions] = useState<ConfigOptgroup[]>();
  const [timer, setTimer] = useState<string>();
  const { user } = useAuth0();

  // check if the user has reached the limit of screenshots per month
  const {
    isLiveSessionsLimitReached,
    areLiveSessionsUnlimited,
    license_session_parallels_count: parallelsLimit,
  } = userLimitsAndStats;

  useEffect(() => {
    const isOsWindows = configForRealTimeTesting.os === '10WINDOWS';
    const isCookieSet = getCookie('englishLayoutAlert_already_displayed');
    const showEnglishLayoutAlert = isOsWindows && !isCookieSet;

    setIsEnglishLayoutAlertActive(showEnglishLayoutAlert);
  }, []);

  useEffect(() => {
    const configs = createConfigsLivetesting(configsLivetesting as RawConfigsLivetesting);
    let { os, browser, version } = configForRealTimeTesting;
    const modifiedOs = defineOsTitleForVnc(os);
    const modifiedBrowser = defineBrowserTitle(browser);
    const value = `${modifiedOs}_${modifiedBrowser}_${version}`;
    const label = `${modifiedBrowser} ${version}`;

    setConfigOption({ value, label, os, browser, version });

    const configOptions = configs
      .reduce((accum: ConfigOptgroup[], { os, browser, version }) => {
        const modifiedOs = defineOsTitleForVnc(os);
        const modifiedBrowser = defineBrowserTitle(browser);

        // get the array index of the option group with the label which contains current screen resolution
        const groupIndex = accum.findIndex((item) => defineOsTitle(os) === item.label);
        const label = `${modifiedBrowser} ${version}`;
        const value = `${modifiedOs}_${modifiedBrowser}_${version}`;
        const singleOptionToPush = { value, label, os, browser, version };

        if (groupIndex > -1 && (os === 'LINUX' || os === '10WINDOWS')) {
          accum[groupIndex].options.push(singleOptionToPush);

          return accum;
        }

        if (groupIndex === -1) {
          if (os === 'LINUX' || os === '10WINDOWS') {
            accum.push({ label: defineOsTitle(os), options: [singleOptionToPush] });

            return accum;
          }
        }

        return accum;
      }, [])
      .sort((x, y) => (x.label === 'Linux' ? -1 : 1));

    setConfigOptions(configOptions);
  }, []);

  useEffect(() => {
    let timer = vncSessionTimeout;

    function startTimer(timer: number) {
      return setInterval(() => {
        let minutes = Math.floor(timer / 60); // the largest round integer less than or equal to the result of time divided being by 60
        let seconds: number | string = timer % 60; // seconds are the remainder of the time divided by 60 (modulus operator)

        if (seconds < 10) {
          seconds = `0${seconds}`; // if the value of seconds is less than 10, then display seconds with a leading zero
        }

        setTimer(`${minutes}:${seconds}`);

        if (--timer < 0) {
          clearInterval(timerID);
          dispatch(uiActions.toggleRealTimeTestingPopup(userDoesntHavePlan ? 'free' : 'paid'));
          dispatch(uiActions.toggleRealTimeTesting(false));
          socket.emit('liveTestingEnded', { sessionId: vncSessionId }); // send vnc session ID in order to close this session on the server so parallel sessions stats counter will be correct
          getUserStatsAndLimits();
        }
      }, 1000);
    }

    const timerID = startTimer(timer);

    return () => clearInterval(timerID);
  }, []);

  // we need to send request to get user limits and statistics data in 1 second in order to make sure that we get actual stats
  function getUserStatsAndLimits(): void {
    const userId = user?.sub;
    const {
      license_product: licenseProduct,
      billing_operator: billingOperator,
      last_order_reference: lastOrderReference,
    } = user?.[app_metadata_namespace]?.comparium?.subscription || {};

    const params = { userId, licenseProduct, billingOperator, lastOrderReference };

    setTimeout(() => socket.emit('getUserData', params), 2000); // send request to get user stats and limits in 2 second in order to make sure that we get actual stats and limits
  }

  async function handleChange(config: ConfigOption) {
    if (isLiveSessionsLimitReached && !areLiveSessionsUnlimited) {
      dispatch(uiActions.toggleDemoreminder('limitOfLivetesting'));
      return;
    }

    let isParallelSessionsLimitReached: boolean;
    const userId = user?.sub;
    const { license_product: licenseProduct } =
      user?.[app_metadata_namespace]?.comparium?.subscription || {};

    try {
      const request = await api.checkIfParallelSessionsLimitReached(userId, licenseProduct);
      isParallelSessionsLimitReached = !request?.data; // if data is equal to true, that means that limit of parallel sessions was not reached
    } catch (error) {
      console.log('error of getting parallel sessions limit', error);
    }

    if (isParallelSessionsLimitReached) {
      dispatch(uiActions.toggleDemoreminder('limitOfParallelTesting'));
      socket.emit('liveTestingEnded', { sessionId: vncSessionId }); // send vnc session ID in order to close this session on the server so parallel sessions stats counter will be correct
      getUserStatsAndLimits();

      return;
    }

    const { os: currentOs, browser: currentBrowser, version: currentVersion } = configOption;
    const { os, browser, version } = config;

    if (currentOs === os && currentBrowser === browser && currentVersion === version) {
      return;
    }

    const newVncSessionId = generateHashForVncSession();
    const configForRealTimeTesting = {
      os,
      browser,
      version,
      urlForVncSession,
      userId,
      vncSessionId: newVncSessionId,
    };

    getUserStatsAndLimits();
    dispatch(uiActions.setConfigForRealTimeTesting(configForRealTimeTesting));
    dispatch(uiActions.toggleRealTimeTestingPreloader(true));
    dispatch(uiActions.toggleRealTimeTesting(false));
  }

  function handleCloseClick(): void {
    dispatch(uiActions.toggleRealTimeTesting(false));
    socket.emit('liveTestingEnded', { sessionId: vncSessionId }); // send vnc session ID in order to close this session on the server so parallel sessions stats counter will be correct
    getUserStatsAndLimits();
  }

  return (
    <div className="realTimeTesting">
      {isEnglishLayoutAlertActive && (
        <EnglishLayoutAlert setIsActive={setIsEnglishLayoutAlertActive} />
      )}
      <header className="realTimeTesting__header">
        {parallelsLimit !== 1 && (
          <Select
            className="realTimeTesting__header-select"
            classNamePrefix="realTimeTesting__header-select"
            value={configOption}
            options={configOptions}
            isSearchable={false}
            onChange={handleChange}
            styles={selectStyles}
            components={selectComponents}
          />
        )}
        {timer && (
          <div className="realTimeTesting__header-timer">
            <Images.sandglass />
            <span className="realTimeTesting__header-timer-count">{timer}</span>
          </div>
        )}
        <button className="realTimeTesting__header-close-btn square-btn" onClick={handleCloseClick}>
          <Icons.cross />
        </button>
      </header>
      <iframe src={vncSessionUrl} className="realTimeTesting__screen" />
    </div>
  );
}

export default React.memo(RealTimeTesting);

// define OS title
function defineOsTitle(os: string): string {
  switch (os) {
    case 'LINUX':
      return 'Linux';

    case 'OSX':
      return 'macOS Catalina';

    case '10WINDOWS':
      return 'Windows 10';
  }
}
