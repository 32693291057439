import { UiState, UiActionTypes } from './types';

const initialState: UiState = {
  url: '',
  screenNumber: 0,
  screenConfigForDeletion: {
    os: '',
    browser: '',
    version: '',
    screenId: '',
  },
  configForRealTimeTesting: {
    os: '',
    browser: '',
    version: '',
    urlForVncSession: 'www.google.com',
    userId: '',
    vncSessionId: '', // id to cancel creation of VNC session which hasn't started yet. It is created on front-end side and sent to the server when we need to cancel creation of VNC session which hasn't started yet.
  },
  credentialsForBasicAuth: { user_auth: undefined, user_pass: undefined },
  areCredentialsForBasicAuthConfirmed: false,
  vncSessionUrl: '', // URL which we need to access VNC session through iframe
  vncSessionTimeout: null,
  vncSessionId: null, // id of VNC session which has already started. It is sent from the server. We need it to cancel creation of VNC session which has already started.
  isURLValid: true,
  isWidthSelected: true,
  isHeaderCloseButtonActive: false,
  isModalActive: false,
  isDialogActive: false,
  isCompareActive: false,
  isCompareMobileActive: false,
  isDemoreminderActive: false,
  isThanksForPurchasePopupActive: false,
  isThanksForPurchaseAlertActive: false,
  isPlanActiveAlertActive: false,
  isPaymentDeatilsUpdatedAlertActive: false,
  isRealTimeTestingPopupActive: false,
  isRealTimeTestingPreloaderActive: false,
  isRealTimeTestingActive: false,
  isConfigMobileNoURLActive: false,
  isConfigDesktopNoURLActive: false,
  isConfigMobileWithURLActive: false,
  areTabsMobileWithURLActive: false,
  massSelectionStatus: false,
  userDoesntHavePlan: null,
  isCompareDialogActive: false,
  isLoginProfileCreatorPopupActive: false,
  isLoginProfileCreatorPopupMobileActive: false,
  isLoginProfileDialogActive: false,
  isContinueWithTrialPageActive: false,
  accountPageTabsValue: 0,
  screensSessionId: null, // we need this session ID to send request to the server to close this session (pqarallel session) in case the user closed browser
  isSmartScrollOn: false,
};

export function uiReducer(state = initialState, action: UiActionTypes): UiState {
  switch (action.type) {
    case 'CHANGE_URL':
      return { ...state, url: action.payload };

    case 'SET_SCREEN_NUMBER':
      return { ...state, screenNumber: action.payload };

    case 'VALIDATE_URL':
      return { ...state, isURLValid: action.payload };

    case 'VALIDATE_WIDTH':
      return { ...state, isWidthSelected: action.payload };

    case 'SET_SCREEN_CONFIG_FOR_DELETION':
      return { ...state, screenConfigForDeletion: { ...action.payload } };

    case 'SET_CONFIG_FOR_REAL_TIME_TESTING':
      return { ...state, configForRealTimeTesting: { ...action.payload } };

    case 'SHOW_CONFIG_MOBILE_NO_URL':
      return { ...state, isConfigMobileNoURLActive: true };

    case 'HIDE_CONFIG_MOBILE_NO_URL':
      return { ...state, isConfigMobileNoURLActive: false };

    case 'TOGGLE_CONFIG_DESKTOP_NO_URL':
      return { ...state, isConfigDesktopNoURLActive: !state.isConfigDesktopNoURLActive };

    case 'HIDE_CONFIG_DESKTOP_NO_URL':
      return { ...state, isConfigDesktopNoURLActive: false };

    case 'SHOW_CONFIG_MOBILE_WITH_URL':
      return { ...state, isConfigMobileWithURLActive: true };

    case 'HIDE_CONFIG_MOBILE_WITH_URL':
      return { ...state, isConfigMobileWithURLActive: false };

    case 'SHOW_TABS_MOBILE_WITH_URL':
      return { ...state, areTabsMobileWithURLActive: true };

    case 'HIDE_TABS_MOBILE_WITH_URL':
      return { ...state, areTabsMobileWithURLActive: false };

    case 'SHOW_HEADER_CLOSE_BUTTON':
      return { ...state, isHeaderCloseButtonActive: true };

    case 'HIDE_HEADER_CLOSE_BUTTON':
      return { ...state, isHeaderCloseButtonActive: false };

    case 'SHOW_DIALOG':
      return { ...state, isDialogActive: true };

    case 'CLOSE_DIALOG':
      return { ...state, isDialogActive: false };

    case 'SHOW_MODAL':
      return { ...state, isModalActive: true };

    case 'CLOSE_MODAL':
      return { ...state, isModalActive: false };

    case 'TOGGLE_SELECTION_STATUS':
      return { ...state, massSelectionStatus: action.payload };

    case 'TOGGLE_DEMOREMINDER':
      return { ...state, isDemoreminderActive: action.payload };

    case 'TOGGLE_USER_DOESNT_HAVE_PLAN':
      return { ...state, userDoesntHavePlan: action.payload };

    case 'TOGGLE_THANKS_FOR_PURCHASE_POPUP':
      return { ...state, isThanksForPurchasePopupActive: action.payload };

    case 'TOGGLE_THANKS_FOR_PURCHASE_ALERT':
      return { ...state, isThanksForPurchaseAlertActive: action.payload };

    case 'TOGGLE_PLAN_ACTIVE_ALERT':
      return { ...state, isPlanActiveAlertActive: action.payload };

    case 'TOGGLE_COMPARE_ACTION':
      return { ...state, isCompareActive: action.payload };

    case 'TOGGLE_COMPARE_MOBILE_ACTION':
      return { ...state, isCompareMobileActive: action.payload };

    case 'TOGGLE_REAL_TIME_TESTING_POPUP':
      return { ...state, isRealTimeTestingPopupActive: action.payload };

    case 'TOGGLE_REAL_TIME_TESTING_PRELOADER':
      return { ...state, isRealTimeTestingPreloaderActive: action.payload };

    case 'TOGGLE_REAL_TIME_TESTING':
      return { ...state, isRealTimeTestingActive: action.payload };

    case 'SET_VNC_SESSION_URL':
      return { ...state, vncSessionUrl: action.payload };

    case 'SET_VNC_SESSION_TIMEOUT':
      return { ...state, vncSessionTimeout: action.payload };

    case 'SET_VNC_SESSION_ID':
      return { ...state, vncSessionId: action.payload };

    case 'TOGGLE_COMPARE_DIALOG':
      return { ...state, isCompareDialogActive: action.payload };

    case 'SET_CREDENTIALS_FOR_BASIC_AUTH':
      return { ...state, credentialsForBasicAuth: action.payload };

    case 'TOGGLE_ARE_CREDENTIALS_FOR_BASIC_AUTH_CONFIRMED':
      return { ...state, areCredentialsForBasicAuthConfirmed: action.payload };

    case 'TOGGLE_LOGIN_PROFILE_CREATOR_POPUP':
      return { ...state, isLoginProfileCreatorPopupActive: action.payload };

    case 'TOGGLE_LOGIN_PROFILE_CREATOR_POPUP_MOBILE':
      return { ...state, isLoginProfileCreatorPopupMobileActive: action.payload };

    case 'TOGGLE_LOGIN_PROFILE_DIALOG':
      return { ...state, isLoginProfileDialogActive: action.payload };

    case 'TOGGLE_PAYMENT_DETAILS_UPDATED_ALERT':
      return { ...state, isPaymentDeatilsUpdatedAlertActive: action.payload };

    case 'TOGGLE_CONTINUE_WITH_TRIAL_PAGE_ACTIVE':
      return { ...state, isContinueWithTrialPageActive: action.payload };

    case 'TOGGLE_ACCOUNT_PAGE_TABS_VALUE':
      return { ...state, accountPageTabsValue: action.payload };

    case 'SET_SCREENS_SESSION_ID':
      return { ...state, screensSessionId: action.payload };

    case 'TOGGLE_SMART_SCROLL_SWITCH':
      return { ...state, isSmartScrollOn: action.payload };

    default:
      return state;
  }
}
