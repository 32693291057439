// core
import React, { useState, useEffect, useRef, useContext, Suspense, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { navigate } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
// components
import Alert from './alert';
import Header from './header';
import Footer from './footer';
import Modal from './modal';
import Dialog from './dialog';
import LoginProfileDialog from './loginProfileDialog';
const CompareDesktop = React.lazy(() => import('./compare/desktop'));
const CompareMobile = React.lazy(() => import('./compare/mobile'));
import DemoReminder from './demoReminder';
import WhatIsNewReminder from './whatIsNewReminder';
import ContinueWithTrial from './continueWithTrial';
import WelcomePopup from './welcomePopup';
import ThanksForPurchase from './thanksForPurchase';
import RealTimeTesting from './realTimeTesting';
import Preloader from './preloader';
import RealTimeTestingPreloader from './realTimeTestingPreloader';
import RealTimeTestingPopup from './realTimeTestingPopup';
import SEO from './seo';
const LoginProfileCreatorPopupDesktop = React.lazy(
  () => import('./loginProfileCreatorPopup/desktop')
);
const LoginProfileCreatorPopupMobile = React.lazy(
  () => import('./loginProfileCreatorPopup/mobile')
);
import { LocaleContext } from './layout';
// redux actions
import { uiActions } from '../store/domains/ui/actions';
import { screensActions } from '../store/domains/screenshots/actions';
import { loginProfilesActions } from '../store/domains/loginProfiles/actions';
import { userLimitsAndStatsActions } from '../store/domains/userLimitsAndStats/actions';
import { configsCheckboxesWithURLActions } from '../store/domains/configsCheckboxesWithURL/actions';
import { configsCheckboxesNoURLActions } from '../store/domains/configsCheckboxesNoURL/actions';
import { configsLivetestingActions } from '../store/domains/configsLivetesting/actions';
// instruments
import { useAuth0 } from '../helpers/auth';
import {
  createConfigsCheckboxes,
  getQueryParameterByName,
  getCookie,
  setCookie,
  dataLayerPush,
  checkIfUserDoesntHavePlan,
  trackingConversionForPaddlePurchase,
  disableScroll,
  runLiveTest,
} from '../helpers';
import { api } from '../api';
//socket
import { _socket } from '../socket';
//default settings
import {
  app_metadata_namespace,
  checkoutOperator,
  linkApiForCreatingPaddletrialLink,
} from '../settings.json';
//styles
import '../styles/fonts.css';
import '../styles/core.scss';
import '../styles/common-styles.scss';
import '../styles/custom-properties.css';
// typescript types
import { RootState } from '../store/rootReducer';

const selectScreensNumber = createSelector(
  (state: RootState) => state.screens,
  (screens) => screens.length
);

const App: FC = ({ children }) => {
  // hooks
  const dispatch = useDispatch();
  const screensNumber = useSelector(selectScreensNumber);
  const isModalActive = useSelector((state: RootState) => state.ui.isModalActive);
  const isThanksForPurchaseAlertActive = useSelector(
    (state: RootState) => state.ui.isThanksForPurchaseAlertActive
  );
  const isPlanActiveAlertActive = useSelector(
    (state: RootState) => state.ui.isPlanActiveAlertActive
  );
  const isPaymentDeatilsUpdatedAlertActive = useSelector(
    (state: RootState) => state.ui.isPaymentDeatilsUpdatedAlertActive
  );
  const isCompareActive = useSelector((state: RootState) => state.ui.isCompareActive);
  const isCompareMobileActive = useSelector((state: RootState) => state.ui.isCompareMobileActive);
  const isDialogActive = useSelector((state: RootState) => state.ui.isDialogActive);
  const isDemoreminderActive = useSelector((state: RootState) => state.ui.isDemoreminderActive);
  const isThanksForPurchasePopupActive = useSelector(
    (state: RootState) => state.ui.isThanksForPurchasePopupActive
  );
  const isRealTimeTestingActive = useSelector(
    (state: RootState) => state.ui.isRealTimeTestingActive
  );
  const isRealTimeTestingPreloaderActive = useSelector(
    (state: RootState) => state.ui.isRealTimeTestingPreloaderActive
  );
  const isRealTimeTestingPopupActive = useSelector(
    (state: RootState) => state.ui.isRealTimeTestingPopupActive
  );
  const isLoginProfileCreatorPopupActive = useSelector(
    (state: RootState) => state.ui.isLoginProfileCreatorPopupActive
  );
  const isLoginProfileCreatorPopupMobileActive = useSelector(
    (state: RootState) => state.ui.isLoginProfileCreatorPopupMobileActive
  );
  const isLoginProfileDialogActive = useSelector(
    (state: RootState) => state.ui.isLoginProfileDialogActive
  );
  const vncSessionId = useSelector((state: RootState) => state.ui.vncSessionId);
  const screensSessionId = useSelector((state: RootState) => state.ui.screensSessionId);
  const userDoesntHavePlan = useSelector((state: RootState) => state.ui.userDoesntHavePlan);
  const userLimitsAndStats = useSelector((state: RootState) => state.userLimitsAndStats);
  const [areProfilesReceived, setAreProfilesReceived] = useState(false); // flag which let us know if lofin profiles were already received from the server
  const [whatIsNewReminderActive, setWhatIsNewReminderActive] = useState(false);
  const [isContinueWithTrialActive, setIsContinueWithTrialActive] = useState(false);
  const [isWelcomePopupActive, setIsWelcomePopupActive] = useState(false);
  const [areConfigsLoaded, setAreConfigsLoaded] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 769 }); // if screen width is more than 768px
  const isMobile = useMediaQuery({ maxWidth: 768 }); // if screen width is less than or equal to 768px
  const socket = useRef(_socket);
  const { path } = useContext(LocaleContext);
  const {
    action,
    loading,
    isAuthenticated,
    user,
    setUser,
    getUser,
    loginWithRedirect,
    isTrial,
    setIsTrial,
    isTrialPopupActive,
    setIsTrialPopupActive,
    isAppSumoAlertActive,
    auth0Client,
    getIdTokenClaims,
  } = useAuth0();

  // destructuring user limits and stats data
  const {
    areStatsReady,
    isLiveSessionsLimitReached,
    areLiveSessionsUnlimited,
  } = userLimitsAndStats;

  // load and fill checkboxes and livetecting configs
  useEffect(() => {
    async function fetchData() {
      const configsCheckboxesPromise = api.getRawConfigsCheckboxes();
      const configsLivetestingPromise = api.getRawConfigsLivetesting();
      const result = await Promise.all([configsCheckboxesPromise, configsLivetestingPromise]);
      const configsCheckboxesRaw = result[0].data;
      const configsLivetestingRaw = result[1].data;
      const configsCheckboxes = createConfigsCheckboxes(configsCheckboxesRaw);

      dispatch(configsCheckboxesWithURLActions.fillConfigs(configsCheckboxes));
      dispatch(configsCheckboxesNoURLActions.fillConfigs(configsCheckboxes));
      dispatch(configsLivetestingActions.fillConfigs(configsLivetestingRaw));
      setAreConfigsLoaded(true);
    }

    fetchData();
  }, []);

  // get response from server on opening VNC connection
  useEffect(() => {
    socket.current.on('VNCSessionData', function (data) {
      const { errorMessage, link, vncSessionTimeout, sessionId } = data;

      if (errorMessage) {
        dispatch(uiActions.toggleRealTimeTestingPopup('error'));
        dispatch(uiActions.toggleRealTimeTestingPreloader(false));
      } else {
        dispatch(uiActions.setVncSessionUrl(link));
        dispatch(uiActions.setVncSessionTimeout(vncSessionTimeout));
        dispatch(uiActions.setVncSessionId(sessionId));
        dispatch(uiActions.toggleRealTimeTesting(true));
        dispatch(uiActions.toggleRealTimeTestingPreloader(false));
      }
    });

    return () => socket.current.removeAllListeners('VNCSessionData');
  }, []);

  // get data about recently created login profile
  useEffect(() => {
    socket.current.on('savedLoginProfileId', function (data) {
      data = { ...data, selected: false };

      dispatch(loginProfilesActions.createProfile(data));
      dispatch(loginProfilesActions.selectProfile(data.id));
    });

    return () => socket.current.removeAllListeners('savedLoginProfileId');
  }, []);

  // get all login profiles for this current user
  useEffect(() => {
    socket.current.on('loginProfiles', (newProfiles) => {
      const { userProfiles } = newProfiles;

      if (userProfiles.length !== 0) {
        const modifiedProfiles = userProfiles.map((item) => ({ ...item, selected: false }));

        dispatch(loginProfilesActions.fillProfiles(modifiedProfiles));
      }
    });

    return () => socket.current.removeAllListeners('loginProfiles');
  }, []);

  // subscription to get user limits and statistics data
  useEffect(() => {
    socket.current.on('userData', (userData) => {
      dispatch(userLimitsAndStatsActions.updateStats(userData));
    });

    return () => socket.current.removeAllListeners('userData');
  }, []);

  // this event is fired when screens session is over
  useEffect(() => {
    socket.current.on('screensEnded', () => {
      const userId = user?.sub;
      const {
        license_product: licenseProduct,
        billing_operator: billingOperator,
        last_order_reference: lastOrderReference,
      } = user?.[app_metadata_namespace]?.comparium?.subscription || {};
      const params = { userId, licenseProduct, billingOperator, lastOrderReference };

      if (userId) socket.current.emit('getUserData', params);
    });

    return () => socket.current.removeAllListeners('screensEnded');
  }, [user]);

  // get screen data
  useEffect(() => {
    const userId = user?.sub;
    const {
      license_product: licenseProduct,
      billing_operator: billingOperator,
      last_order_reference: lastOrderReference,
    } = user?.[app_metadata_namespace]?.comparium?.subscription || {};
    const params = { userId, licenseProduct, billingOperator, lastOrderReference };

    socket.current.on('screens', (data) => {
      dispatch(screensActions.setScreen(data));

      if (userId) socket.current.emit('getUserData', params);

      sendDataAboutScreensToGTM(data);
    });

    return () => socket.current.removeAllListeners('screens');
  }, [user]);

  useEffect(() => {
    function closeSession() {
      if (vncSessionId) {
        socket.current.emit('liveTestingEnded', { sessionId: vncSessionId }); // send vnc session ID in order to close this session on the server so parallel sessions stats counter will be correct
      }

      if (screensSessionId) {
        socket.current.emit('screenshotEnded', { sessionId: screensSessionId });
      }
    }

    window.addEventListener('beforeunload', closeSession);

    return () => window.removeEventListener('beforeunload', closeSession);
  }, [vncSessionId, screensSessionId]);

  // send request to get user limits and statistics data
  useEffect(() => {
    const userId = user?.sub;
    const {
      license_product: licenseProduct,
      billing_operator: billingOperator,
      last_order_reference: lastOrderReference,
    } = user?.[app_metadata_namespace]?.comparium?.subscription || {};
    const params = { userId, licenseProduct, billingOperator, lastOrderReference };

    if (userId) socket.current.emit('getUserData', params);
  }, [user]);

  // send request to get all login profiles
  useEffect(() => {
    const userId = user?.sub;
    if (userId && !areProfilesReceived) {
      socket.current.emit('getLoginProfiles', { userId });
      setAreProfilesReceived(true);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const sessionIdUrl = getQueryParameterByName('session');
      const sessionIdSs = window.sessionStorage.getItem('compariumSessionID');

      if (sessionIdUrl && sessionIdSs && sessionIdUrl === sessionIdSs) return;

      const userDoesntHavePlan = checkIfUserDoesntHavePlan(user, app_metadata_namespace);

      dispatch(uiActions.toggleUserDoesntHavePlan(userDoesntHavePlan));
    }
  }, [user]);

  useEffect(() => {
    let timer;

    if (isThanksForPurchaseAlertActive) checkUser();

    async function checkUser() {
      const user = await getUser();
      const status =
        user?.[app_metadata_namespace]?.comparium?.subscription.status.toLowerCase() || {};

      if (status === 'active') activatePlan(user);
      else timer = setTimeout(checkUserInfoViaTimer, 10000);
    }

    async function checkUserInfoViaTimer() {
      const user = await getUser();
      const status =
        user?.[app_metadata_namespace]?.comparium?.subscription.status.toLowerCase() || {};

      if (status === 'active') activatePlan(user);
    }

    function activatePlan(user) {
      dispatch(uiActions.toggleUserDoesntHavePlan(false));
      setUser(user);
      dispatch(uiActions.toggleThanksForPurchaseAlert(false));
      dispatch(uiActions.togglePlanActiveAlert(true));
      clearInterval(timer);
      setTimeout(() => dispatch(uiActions.togglePlanActiveAlert(false)), 15000);
      if (isTrial) setIsTrial(false);
    }

    return () => clearInterval(timer);
  }, [isThanksForPurchaseAlertActive]);

  // if cookie 'whatIsNewReminder' is not set, show 'what is new reminder' popup
  useEffect(() => {
    if (path === '/request/trial') return;
    if (path === '/redemption/login') return;

    // it is important to use the condition "isTrial === false" instead of "!isTrial"
    // because we mustn't execute this 'if' branch code if isTrial variable is undefined
    if (isTrial === false) {
      const showWhatIsNewReminder = !getCookie('comparium_whatIsNewReminder');
      const showWelcomePopup = !getCookie('comparium_welcomePopup');

      switch (userDoesntHavePlan) {
        case true:
          if (showWelcomePopup && !whatIsNewReminderActive) {
            setIsWelcomePopupActive(true);
          }

          if (showWhatIsNewReminder && !showWelcomePopup && !isWelcomePopupActive) {
            setWhatIsNewReminderActive(true);
          }
          break;

        case false:
          if (showWhatIsNewReminder) setWhatIsNewReminderActive(true);
          break;
      }
    }
  }, [userDoesntHavePlan, isTrial]);

  // disable F5 to prevent web browser from refreshing
  useEffect(() => {
    function disableF5(event: KeyboardEvent) {
      if ((event.which || event.keyCode) === 116) event.preventDefault();
    }

    document.addEventListener('keydown', disableF5);

    return () => document.removeEventListener('keydown', disableF5);
  }, []);

  useEffect(() => {
    if (isTrialPopupActive && user) {
      const product_id = checkoutOperator.paddle.trial;
      const user_id = user?.sub;
      const user_email = user?.email;
      const data = { product_id, user_id, user_email };
      const postData = new FormData();
      postData.append('data', JSON.stringify(data));

      axios
        .post(linkApiForCreatingPaddletrialLink, postData)
        .then((data) => {
          if (data.data?.success) {
            const override = data.data.response.url;
            const email = user?.email;

            if (override) {
              disableScroll(true);
              Paddle.Checkout.open({ override, email, successCallback, closeCallback });
            }
          } else {
            setIsTrialPopupActive(false);
            setIsTrial(false);
          }
        })
        .catch((error) => console.error('error after attempt to get trial paylink', error));

      function successCallback(data) {
        setIsTrialPopupActive(false);
        dispatch(uiActions.toggleThanksForPurchasePopup(true));
        disableScroll(false);

        Paddle.Order.details(data?.checkout?.id, (data) =>
          trackingConversionForPaddlePurchase(data)
        );
      }

      function closeCallback() {
        disableScroll(false);
        setIsTrialPopupActive(false);
        setIsContinueWithTrialActive(true);
      }
    }
  }, [isTrialPopupActive, user]);

  // execute action if we get it from Auth0 parameters
  useEffect(() => {
    if (action === 'go_to_pricing_page') {
      dispatch(uiActions.toggleAccountPageTabsValue(1));
      navigate('/account');
    }
  }, [action]);

  useEffect(() => {
    const aff_offer_id = getQueryParameterByName('aff_offer_id');
    const aff_click_id = getQueryParameterByName('aff_click_id');

    if (aff_offer_id) {
      setCookie('aff_offer_id', aff_offer_id, 30);
    }

    if (aff_click_id) {
      setCookie('aff_click_id', aff_click_id, 30);
    }
  }, []);

  // check if URL includes query parameters 'livetesting_url' and 'livetesting_config'
  // if so, we send request to start live testing session
  useEffect(() => {
    const url = getQueryParameterByName('livetesting_url');
    const livetestingConfig = getQueryParameterByName('livetesting_config');
    const isQueryParamFromExtension = getQueryParameterByName('livetesting_from_browser_extension');

    if (url && livetestingConfig && user) {
      const [os, browser, version] = livetestingConfig.split('_');

      const livetestParams = {
        socket: socket.current,
        dispatch,
        uiActions,
        isLiveSessionsLimitReached,
        areLiveSessionsUnlimited,
        user,
        api,
        os,
        browser,
        version,
        url,
      };

      runLiveTest(livetestParams);

      // if URL includes query parameter livetesting_from_browser_extension, we send info
      // to GTM and mark it requested from browser extension
      if (isQueryParamFromExtension) {
        sendDataAboutLiveTestingToGTM(os, browser, version);
      }
    }
  }, [user]);

  // alert texts
  const planActiveStrongText = <span>Payment successful!</span>;
  const planActiveText = <span>Benefit from Comparium PRO features.</span>;
  const thanksForPurchaseText = (
    <span>Thank you for your order. Please wait while we process your payment.</span>
  );
  const paymentDeatilsUpdatedText = (
    <span>Please wait while the information is being updated.</span>
  );
  const appsumoText = <span>Thank you for using. Your Comparium plan is activated.</span>;

  if (!areConfigsLoaded || loading || isAuthenticated === undefined) {
    return <Preloader />;
  }

  if (isAuthenticated === false && path !== '/request/trial' && path !== '/redemption/login') {
    const loginHint = getQueryParameterByName('login_hint');
    const screenHint = getQueryParameterByName('screen_hint');
    const action = getQueryParameterByName('action');
    let params = {
      targetUrl: window.location.href,
      pathname: window.location.pathname,
      login_hint: loginHint,
      screen_hint: screenHint,
      action,
    };

    loginWithRedirect(params);

    return <Preloader />;
  }

  if (!areStatsReady && path !== '/request/trial' && path !== '/redemption/login') {
    return <Preloader />;
  }

  return (
    <>
      <SEO />
      {isPlanActiveAlertActive && (
        <Alert
          backgroundColor={'#75D84C'}
          strongText={planActiveStrongText}
          text={planActiveText}
        />
      )}
      {isAppSumoAlertActive && <Alert backgroundColor={'#75D84C'} text={appsumoText} />}
      {isThanksForPurchaseAlertActive && (
        <Alert backgroundColor={'#3DC3E2'} text={thanksForPurchaseText} />
      )}
      {isPaymentDeatilsUpdatedAlertActive && (
        <Alert backgroundColor={'#3DC3E2'} text={paymentDeatilsUpdatedText} />
      )}
      {isPaymentDeatilsUpdatedAlertActive && (
        <Alert backgroundColor={'#3DC3E2'} text={paymentDeatilsUpdatedText} />
      )}
      <Header />
      {children}
      <Footer />
      {screensNumber > 0 && isModalActive && <Modal />}
      {isDialogActive && <Dialog />}
      {isDesktop && isCompareActive && (
        <Suspense fallback={<Preloader />}>
          <CompareDesktop />
        </Suspense>
      )}
      {isMobile && isCompareMobileActive && (
        <Suspense fallback={<Preloader />}>
          <CompareMobile />
        </Suspense>
      )}
      {isDesktop && whatIsNewReminderActive && (
        <WhatIsNewReminder setReminderActive={setWhatIsNewReminderActive} />
      )}
      {isWelcomePopupActive && <WelcomePopup setIsWelcomePopupActive={setIsWelcomePopupActive} />}
      {isLoginProfileCreatorPopupActive && (
        <Suspense fallback={<Preloader />}>
          <LoginProfileCreatorPopupDesktop />
        </Suspense>
      )}
      {isLoginProfileCreatorPopupMobileActive && (
        <Suspense fallback={<Preloader />}>
          <LoginProfileCreatorPopupMobile />
        </Suspense>
      )}
      {isLoginProfileDialogActive && <LoginProfileDialog />}
      {isThanksForPurchasePopupActive && <ThanksForPurchase />}
      {isRealTimeTestingActive && <RealTimeTesting />}
      {isRealTimeTestingPreloaderActive && <RealTimeTestingPreloader />}
      {isRealTimeTestingPopupActive && <RealTimeTestingPopup />}
      {isDemoreminderActive && <DemoReminder />}
      {isContinueWithTrialActive && (
        <ContinueWithTrial setIsContinueWithTrialActive={setIsContinueWithTrialActive} />
      )}
    </>
  );
};

export default App;

// send all necessary info to Google Tag Manager
export function sendDataAboutScreensToGTM(screen: ScreenConfig) {
  // send info about number of each OS to Google Tag Manager
  const platformsParams = {
    event: 'customEvent',
    eventCategory: 'info',
    eventAction: 'platforms',
    eventLabel: screen.os,
    eventValue: 1,
  };

  dataLayerPush(platformsParams);

  // send info about number of each browser to Google Tag Manager
  const browsersParams = {
    event: 'customEvent',
    eventCategory: 'info',
    eventAction: 'browsers',
    eventLabel: screen.browser,
    eventValue: 1,
  };

  dataLayerPush(browsersParams);

  // send info about resolutions to Google Tag Manager
  const screenParams = {
    event: 'customEvent',
    eventCategory: 'info',
    eventAction: 'screen widths',
    eventLabel: screen.resolution,
    eventValue: 1,
  };

  dataLayerPush(screenParams);
}

function sendDataAboutLiveTestingToGTM(os: string, browser: string, version: string) {
  // send info about number of each OS to Google Tag Manager
  const platformsParams = {
    event: 'customEvent',
    eventCategory: 'info',
    eventAction: 'platforms_vnc (ext)',
    eventLabel: os,
    eventValue: 1,
  };

  dataLayerPush(platformsParams);

  // send info about number of each browser to Google Tag Manager
  const browsersParams = {
    event: 'customEvent',
    eventCategory: 'info',
    eventAction: 'browsers_vnc (ext)',
    eventLabel: browser,
    eventValue: 1,
  };

  dataLayerPush(browsersParams);

  // send info about configs to Google Tag Manager
  const capabilities = `${os}_${browser}_${version}`;
  const capabilitiesParams = {
    event: 'customEvent',
    eventCategory: 'info',
    eventAction: 'capabilities_vnc (ext)',
    eventLabel: capabilities,
    eventValue: 1,
  };

  dataLayerPush(capabilitiesParams);
}
