/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// You can delete this file if you're not using it

// core
import ReactDOM from 'react-dom';
// components
import CustomLayout from './wrapPageElement';

export const wrapPageElement = CustomLayout;

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
}
