import arrowNext from './arrow-next.inline.svg';
import arrowPrev from './arrow-prev.inline.svg';
import cross from './cross.inline.svg';
import update from './update.inline.svg';
import deleteIcon from './delete.inline.svg';
import arrowNextSmall from './arrow-next-small.inline.svg';
import arrowPrevSmall from './arrow-prev-small.inline.svg';
import plus from './plus.inline.svg';
import plusMini from './plus-mini.inline.svg';
import updateMiniWhite from './update-mini-white.inline.svg';
import deleteMiniWhite from './delete-mini-white.inline.svg';
import checkIcon from './check.inline.svg';
import checkIconBold from './check-bold.inline.svg';
import settings from './settings.inline.svg';
import swapIcon from './swapIcon.inline.svg';
import preloaderIcon from './preloader.inline.svg';
import errorIcon from './error.inline.svg';
import angleIcon from './angle.inline.svg';
import uploadIcon from './upload.inline.svg';
import crossSmallIcon from './cross-small.inline.svg';
import arrowNextBig from './arrow-next-big.inline.svg';
import arrowPrevBig from './arrow-prev-big.inline.svg';
import infoIcon from './info.inline.svg';
import pencilHollow from './pencil-hollow.inline.svg';
import bin from './bin.inline.svg';
import logo from './logo.svg';

export default {
  arrowNext,
  arrowPrev,
  arrowNextSmall,
  arrowPrevSmall,
  cross,
  update,
  deleteIcon,
  plus,
  plusMini,
  checkIcon,
  checkIconBold,
  updateMiniWhite,
  deleteMiniWhite,
  settings,
  swapIcon,
  preloaderIcon,
  errorIcon,
  angleIcon,
  uploadIcon,
  crossSmallIcon,
  arrowNextBig,
  arrowPrevBig,
  infoIcon,
  pencilHollow,
  bin,
  logo,
};
