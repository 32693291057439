import { CheckboxConfig, ScreenConfig } from './../commonTypes';
import {
  FillConfigsWithUrlAction,
  CheckConfigWithUrlAction,
  CheckConfigsWithUrlAction,
  CheckAllConfigsWithUrlAction,
  CheckBrowserGroupConfigsWithUrlAction,
  CheckOSGroupConfigsWithUrlAction,
  UncheckConfigsWithUrlAction,
  DisableConfigsWithUrlAction,
  EnableAllConfigsWithUrlAction,
  EnableConfigsWithUrlAction,
} from './types';

export const configsCheckboxesWithURLActions = {
  fillConfigs(config: CheckboxConfig[]): FillConfigsWithUrlAction {
    return { type: 'FILL_CONFIGS_WITH_URL', payload: config };
  },
  checkConfig(config: { id: string; checked: boolean }): CheckConfigWithUrlAction {
    return { type: 'CHECK_CONFIG_WITH_URL', payload: config };
  },
  checkConfigs(configs: ScreenConfig[]): CheckConfigsWithUrlAction {
    return { type: 'CHECK_CONFIGS_WITH_URL', payload: configs };
  },
  uncheckConfigs(configs: string[]): UncheckConfigsWithUrlAction {
    return { type: 'UNCHECK_CONFIGS_WITH_URL', payload: configs };
  },
  checkAllConfigs(): CheckAllConfigsWithUrlAction {
    return { type: 'CHECK_ALL_CONFIGS_WITH_URL' };
  },
  checkBrowserGroupConfigs(target: string): CheckBrowserGroupConfigsWithUrlAction {
    return { type: 'CHECK_BROWSER_GROUP_CONFIGS_WITH_URL', payload: target };
  },
  checkOSGroupConfigs(target: string): CheckOSGroupConfigsWithUrlAction {
    return { type: 'CHECK_OS_GROUP_CONFIGS_WITH_URL', payload: target };
  },
  disableConfigs(browsers: string[]): DisableConfigsWithUrlAction {
    return { type: 'DISABLE_CONFIGS_WITH_URL', payload: browsers };
  },
  enableAllConfigs(): EnableAllConfigsWithUrlAction {
    return { type: 'ENABLE_ALL_CONFIGS_WITH_URL' };
  },
  enableConfigs(browsers: string[]): EnableConfigsWithUrlAction {
    return { type: 'ENABLE_CONFIGS_WITH_URL', payload: browsers };
  },
};

export type configsCheckboxesWithURLActionsType = typeof configsCheckboxesWithURLActions;
