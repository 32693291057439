import { UserLimitsAndStatsActionTypes } from './types';
import { StatsAndLimitsType } from '../../../types';

const initialState: StatsAndLimitsType = {
  license_subscription_vnc_count: null, // vnc sessions limit
  license_subscription_vnc_period: null,
  license_subscription_screens_count: null, // screens limit
  license_subscription_screens_period: null,
  license_session_parallels_count: null, // parallel sessions Limit
  count_parallel_tests_for_now: null, // current number of parallel sessions
  license_session_screens_count: null,
  license_session_resolutions_count: null,
  license_period_days: null,
  license_trial_days: null,
  count_screens_for_period: null, // current number of screens
  count_vnc_for_period: null, // current number of vnc sessions
  isScreensLimitReached: null,
  isLiveSessionsLimitReached: null,
  isParallelSessionsLimitReached: null,
  areScreensUnlimited: null,
  areLiveSessionsUnlimited: null,
  areCheckedConfigsUnlimitted: null,
  areCheckedResolutionsUnlimitted: null,
  areStatsReady: null,
  license_short_name: null,
  license_period_group: null, // day, month, year
  license_period: null, // number of license_period_group (1, 2)
};

export function userLimitsAndStatsReducer(
  state = initialState,
  action: UserLimitsAndStatsActionTypes
): StatsAndLimitsType {
  switch (action.type) {
    case 'UPDATE_USER_LIMITS_AND_STATS':
      const {
        license_subscription_screens_count: screensLimit,
        count_screens_for_period: screensCount,
        license_subscription_vnc_count: vncLimit,
        count_vnc_for_period: vncCount,
        license_session_parallels_count: parallelsLimit,
        count_parallel_tests_for_now: parallelsCount,
        license_session_screens_count: allowedNumberOfCheckedCheckboxes,
        license_session_resolutions_count: allowedNumberOfCheckedResolutions,
      } = action.payload;
      const isScreensLimitReached = screensLimit - screensCount <= 0;
      const isLiveSessionsLimitReached = vncLimit - vncCount <= 0;
      const isParallelSessionsLimitReached = parallelsLimit - parallelsCount <= 0;
      const areScreensUnlimited = screensLimit === 0; // if license_subscription_screens_count is equal to 0 - that means that it is unlimited
      const areLiveSessionsUnlimited = vncLimit === 0; // if license_subscription_vnc_count is equal to 0 - that means that it is unlimited
      const areCheckedConfigsUnlimitted = allowedNumberOfCheckedCheckboxes === 0; // if license_session_screens_count is equal to 0 - that means that it is unlimited
      const areCheckedResolutionsUnlimitted = allowedNumberOfCheckedResolutions === 0; // if license_session_resolutions_count is equal to 0 - that means that it is unlimited

      const areStatsReady = !(
        screensLimit == null ||
        screensCount == null ||
        vncLimit == null ||
        vncCount == null ||
        parallelsLimit == null ||
        parallelsCount == null
      );

      return {
        ...state,
        ...action.payload,
        isScreensLimitReached,
        isLiveSessionsLimitReached,
        isParallelSessionsLimitReached,
        areScreensUnlimited,
        areLiveSessionsUnlimited,
        areCheckedConfigsUnlimitted,
        areCheckedResolutionsUnlimitted,
        areStatsReady,
      };

    default:
      return state;
  }
}
