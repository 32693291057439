// core
import React, { useEffect, useRef, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
// instruments
import { setCookie } from '../../helpers';
// styles
import './welcomePopup.scss';
// images
import Images from './images';
import Icons from '../../images';
// typescript types
import { RootState } from '../../store/rootReducer';
type Props = { setIsWelcomePopupActive: Function };

function WelcomePopup({ setIsWelcomePopupActive }: Props): ReactElement {
  // hooks
  const dispatch = useDispatch();
  const userLimitsAndStats = useSelector((state: RootState) => state.userLimitsAndStats);
  const ref = useRef(null);

  useEffect(() => {
    function hide(event: MouseEvent): void {
      if (!ref.current.contains(event.target)) close();
    }

    document.addEventListener('click', hide);

    return () => document.removeEventListener('click', hide);
  }, []);

  // destructuring user limits and stats data
  const {
    license_subscription_vnc_count: vncLimit,
    license_subscription_screens_count: screensLimit,
  } = userLimitsAndStats;

  function close(): void {
    setIsWelcomePopupActive(false);
    setCookie('comparium_welcomePopup', 1);
  }

  function navigateToPricingTab() {
    dispatch(uiActions.toggleAccountPageTabsValue(1));
    setIsWelcomePopupActive(false);
    navigate('/account');
  }

  return (
    <div className="welcomePopup popup-overlay">
      <div className="welcomePopup__window popup" ref={ref}>
        <button className="welcomePopup__close-btn" onClick={close}>
          <Icons.cross />
        </button>
        <img className="welcomePopup__welcome-logo" src={Images.welcomeLogo} alt="hello" />
        <h1 className="welcomePopup__heading">Hello</h1>
        <p className="welcomePopup__text">
          You are currently using the Free Plan, according to which once a month the following
          functionality will be available to you:
        </p>
        <ul className="welcomePopup__limits">
          <li className="welcomePopup__limits-item">
            <img src={Images.camera} alt="camera" className="welcomePopup__limits-item-icon" />{' '}
            {screensLimit} Screenshots
          </li>
          <li className="welcomePopup__limits-item">
            <img src={Images.pointer} alt="pointer" className="welcomePopup__limits-item-icon" />
            {vncLimit} Live sessions
          </li>
        </ul>
        <p className="welcomePopup__more-info">
          Visit the{' '}
          <button className="welcomePopup__more-info-link" onClick={navigateToPricingTab}>
            pricing page
          </button>{' '}
          to explore other plans
        </p>
        <button
          className="welcomePopup__continue-btn orange-gradient-btn one-border-active"
          onClick={close}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default WelcomePopup;
