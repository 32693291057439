// core
import React from 'react';
import { components } from 'react-select';
// instruments
import { defineOsBigIconForVnc, defineBrowserBigIcon, defineOsTitleForVnc } from '../../../helpers';
// images
import Icons from '../../../images';

const { SingleValue, Option, GroupHeading } = components;

function CustomOption(props) {
  const { label } = props.data;

  return (
    <Option {...props}>
      {props.isSelected && <Icons.checkIcon />}
      <span className="realTimeTesting__header-select__option-label">{label}</span>
    </Option>
  );
}

function CustomSingleValue(props) {
  const { label, os, browser } = props.data;
  const osIcon = defineOsBigIconForVnc(os);
  const browserIcon = defineBrowserBigIcon(browser);

  return (
    <SingleValue {...props}>
      <span className="realTimeTesting__header-select__single-value-label">
        <img src={osIcon} alt="" className="realTimeTesting__header-select__single-value-icon" />
        {defineOsTitleForVnc(os)}
      </span>
      <span className="realTimeTesting__header-select__single-value-label">
        <img
          src={browserIcon}
          alt=""
          className="realTimeTesting__header-select__single-value-icon"
        />
        {label}
      </span>
    </SingleValue>
  );
}

function CustomGroupHeading(props) {
  return (
    <GroupHeading {...props}>
      <span>{props.children}</span>
      {props.children !== 'Linux' && props.children !== 'Windows 10' && (
        <span className="realTimeTesting__header-select__group-heading-coming-soon">
          COMING SOON
        </span>
      )}
    </GroupHeading>
  );
}

export const selectComponents = {
  SingleValue: CustomSingleValue,
  Option: CustomOption,
  GroupHeading: CustomGroupHeading,
  DropdownIndicator: () => null,
};
